import {
  getShareInitParams,
  GetShareInitParamsProps,
} from '../getShareInitParams';

type PropsType = {
  buttonsNode: HTMLElement | null;
  handleFocus?: (event: Event) => void;
} & GetShareInitParamsProps;

/**
 * Функция, инициализирующая кнопки шаринга
 * @param buttonsNode - node узел кнопок
 * @param handleFocus - слушатель на фокус элемента
 * @param clusterUrl - url кластера
 * @param clusterTitle - заголовок кластера
 * @param styles - объект стилей для кнопок
 * @param stylesForButton - мапка стилей для каждой кнопки отдельно
 * @param extraButtons - дополнительный список кнопок
 * @param setCounters - флаг установки счетчиков
 * @param customOrder - кастомный порядок соцсетей
 */
export const initShareButtons = ({
  buttonsNode,
  handleFocus,
  ...initParams
}: PropsType): Promise<Window['RamblerShare']> =>
  new Promise((resolve) => {
    const initScript = () => {
      const params = getShareInitParams(initParams);

      resolve(window.RamblerShare.init(buttonsNode, params));

      buttonsNode?.childNodes.forEach((element) => {
        const child = element as HTMLDivElement;

        child.setAttribute('tabIndex', '0');

        if (handleFocus) {
          child.addEventListener('focus', handleFocus);
        }
      });
    };

    if (window.RamblerShare) {
      initScript();
    } else {
      const script = document.createElement('script');

      script.onload = initScript;
      script.async = true;
      script.src = 'https://developers.rambler.ru/likes/v1/widget.js';
      document.head.appendChild(script);
    }
  });
