import { EmbedParagraph } from '../components/EmbedParagraph';
import { MediaParagraph } from '../components/MediaParagraph';
import { TagParagraph } from '../components/TagParagraph';
import { TextParagraph } from '../components/TextParagraph';
import {
  TAGS_LIST,
  NO_INCREMENT_TAGS_LIST,
  EMBEDS_CONFIG,
  MEDIA_LIST,
  PARAGRAPH_TYPE,
} from '../config';

/** Мапка получения компонента параграфа по его типу */
export const paragraphTypesList = {
  [PARAGRAPH_TYPE.TEXT]: TextParagraph,
  [PARAGRAPH_TYPE.INCREMENT_TAG]: TagParagraph,
  [PARAGRAPH_TYPE.NO_INCREMENT_TAG]: TagParagraph,
  [PARAGRAPH_TYPE.EMBED]: EmbedParagraph,
  [PARAGRAPH_TYPE.MEDIA]: MediaParagraph,
};

/**
 * Проверка параграфа на тегированность
 * @param text - текст параграфа
 */
const checkType = <
  T extends { flag: string | RegExp; extraFlag?: string | RegExp },
>(
  text: string,
  config: T[],
): T | undefined => {
  return config.find((item) => {
    const { flag, extraFlag } = item;

    return (
      text.search(flag) !== -1 || (extraFlag && text.search(extraFlag) !== -1)
    );
  });
};

/**
 * Получение типа параграфа и конфигурации работы с ним
 * @param text - текст параграфа
 */
export const getParagraphType = (text: string) => {
  let config = null;
  let type = PARAGRAPH_TYPE.TEXT;

  config = checkType(text, NO_INCREMENT_TAGS_LIST);

  if (config) {
    type = PARAGRAPH_TYPE.NO_INCREMENT_TAG;
  }

  if (!config) {
    config = checkType(text, TAGS_LIST);

    if (config) {
      type = PARAGRAPH_TYPE.INCREMENT_TAG;
    }
  }

  if (!config) {
    config = checkType(text, EMBEDS_CONFIG);

    if (config) {
      type = PARAGRAPH_TYPE.EMBED;
    }
  }

  if (!config) {
    config = checkType(text, MEDIA_LIST);

    if (config) {
      type = PARAGRAPH_TYPE.MEDIA;
    }
  }

  return {
    type,
    config,
  };
};

/**
 * Добавляет класс для плейсхолдера, пока фото/видео в тексте кластера не загрузятся
 * @param text – текст кластера
 */
export const addMediaLoadedClass = (text: string) =>
  text
    .replace('article__image-wrapper', 'article__image-wrapper--loading')
    .replace('article__video-wrapper', 'article__video-wrapper--loading');

/**
 * Добавляет aria-hidden изображению в тексте кластера
 * @param text – текст медиа параграфа
 */
export const addAriaHidden = (text: string) =>
  text.replace(
    '<div class="article__image-wrapper"><img',
    '<div class="article__image-wrapper"><img aria-hidden="true" ',
  );

/**
 * Обрабатывает медиа параграф, добавляет классы или атрибуты
 * @param text – текст медиа параграфа
 */
export const injectMediaParams = (text: string) => {
  if (!text) return text;

  const textWithAlt = addAriaHidden(text);

  return addMediaLoadedClass(textWithAlt);
};
