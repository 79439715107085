import { CoreDraftBlockType } from 'config/constants/draft';
import { splitByParagraphs } from 'utils/splitByParagraphs';

import { BLOCKQUOTE_OPEN_TAG_INDEX } from '../config';

import { LinkMention } from './getCorrectMentions';

const STRING_FOR_SPLIT = '\n\n';
const LINK_TYPES = ['R_LINK', 'LINK'];

export enum UNICODES_SYMBOL {
  ampersand = '&',
  doubleQuotes = '"',
  singleQuotes = "'",
  less = '<',
  more = '>',
}

/** Символы которые в теле кластера приходят юникодом */
const UNICODES_BY_SYMBOL = {
  [UNICODES_SYMBOL.ampersand]: '&amp;',
  [UNICODES_SYMBOL.doubleQuotes]: '&quot;',
  [UNICODES_SYMBOL.singleQuotes]: '&#x27;',
  [UNICODES_SYMBOL.less]: '&lt;',
  [UNICODES_SYMBOL.more]: '&gt;',
};

type ArticleBlockType = {
  text: string;
  startOffset: number;
  isBlockquote: boolean;
};

/**
 * Подсчет оффсетов учитывая спецсимволы до начала ссылки и внутри нее
 * Тк range.offset из draft считается по чистой строке, а в clusterBody приходит с тегами и спецсимволами
 * то нужно учитывать это при подсчете офсета
 * Пример из clusterBody:
 * '<blockquote>«Наши &quot;оценки военные&quot; не изменились — Россия, президент [РФ Владимир] Путин, военные хотят взять Киев, и они продолжают добиваться прогресса», — заявила она.</blockquote>'
 * Пример из draft:
 * "«Наши "оценки военные" не изменились — Россия, президент [РФ Владимир] Путин, военные хотят взять Киев, и они продолжают добиваться прогресса», — заявила она."
 * @param blockText – текст блока из драфта
 * @param range – данные оффсета из драфта
 */
const getSpecialCharsOffset = (blockText: string, range: RawDraftEntityRange) =>
  Object.keys(UNICODES_BY_SYMBOL).reduce(
    (offsets, symbol) => {
      const unicode = UNICODES_BY_SYMBOL[symbol as UNICODES_SYMBOL];
      const symbolRegex = new RegExp(symbol, 'g');

      const linkText = blockText.slice(
        range.offset,
        range.offset + range.length,
      );
      const specialCharsBeforeLinkCount =
        blockText.slice(0, range.offset).match(symbolRegex)?.length || 0;
      const specialCharsInLinkCount = linkText.match(symbolRegex)?.length || 0;
      const newOffsets = { ...offsets };

      newOffsets.startLinkOffset =
        offsets.startLinkOffset +
        specialCharsBeforeLinkCount * unicode.length -
        specialCharsBeforeLinkCount;

      newOffsets.intoLinkOffset =
        offsets.intoLinkOffset +
        specialCharsInLinkCount * unicode.length -
        specialCharsInLinkCount;

      return newOffsets;
    },
    { startLinkOffset: 0, intoLinkOffset: 0 },
  );

/**
 * Получение оффсетов для ссылок в статье из драфта. В основном для ссылок в цитатах.
 * Пример кластера с автотегом в цитате /world/48222833-tass-ssha-obvinili-rossiyu-v-namerenii-vzyat-kiev/ для теста
 * @param article – тело кластера
 * @param draft – данные для разных блоков тела кластера
 * @returns – массив объектов с офсетами для вставки ссылок в цитаты
 */
export const getLinksMentions = (
  article: ClusterData['body'],
  draft: RawDraftContentState,
) => {
  if (!draft?.blocks) {
    return [];
  }

  const splittedArticle = splitByParagraphs(article, STRING_FOR_SPLIT);
  const blockquoteDrafts =
    draft.blocks?.filter(
      (blockItem) =>
        blockItem?.type === CoreDraftBlockType.blockquote &&
        !!blockItem.text.trim(),
    ) || [];

  const articleBlocksData = splittedArticle.reduce(
    (acc: ArticleBlockType[], blockText, index) => {
      acc.push({
        text: blockText,
        // Считаем с какого по счету символа от начала статьи начинается блок с цитатой
        startOffset:
          index > 0
            ? // Учитываем длину предыдущего блока и переносы
              acc[index - 1].startOffset +
              acc[index - 1].text.length +
              STRING_FOR_SPLIT.length
            : 0,
        isBlockquote: blockText.startsWith('<blockquote>'),
      });

      return acc;
    },
    [],
  );

  const articleBlockquotesData = articleBlocksData.filter(
    (blockItem) => blockItem.isBlockquote,
  );

  // если цитат в кластере нет, то выходим из функции
  if (!articleBlockquotesData.length) {
    return [];
  }

  // Считаем оффсеты для каждой цитаты
  return blockquoteDrafts.reduce((acc: LinkMention[], blockItem, index) => {
    blockItem.entityRanges.forEach((range) => {
      const isLink = LINK_TYPES.includes(draft.entityMap[range.key]?.type);

      if (isLink) {
        const { startLinkOffset, intoLinkOffset } = getSpecialCharsOffset(
          blockItem?.text,
          range,
        );
        const startOffset = articleBlockquotesData[index]?.startOffset ?? 0;

        const fromOffset =
          startOffset +
          range.offset +
          BLOCKQUOTE_OPEN_TAG_INDEX +
          startLinkOffset;

        acc.push({
          // Учитываем длину спецсимволов в длине ссылок
          length: range.length + intoLinkOffset,
          offset: fromOffset,
          range: {
            from: fromOffset,
            to: fromOffset + range.length + intoLinkOffset,
          },
          href: draft.entityMap[range.key].data?.href,
        });
      }
    });

    return acc;
  }, []);
};
