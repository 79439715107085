import React, { memo, useEffect, useRef } from 'react';

export type TelegramPropsType = {
  user: string;
  post: string;
};

/**
 * Компонент эмбеда телеграма.
 * @param user - id юзера, опубликовавшем пост;
 * @param post - id поста.
 */
export const Telegram = memo(
  ({ user, post }: TelegramPropsType) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!ref.current || !user || !post) {
        return;
      }

      const node = document.createElement('script');

      node.async = false;
      node.src = 'https://telegram.org/js/telegram-widget.js?21';
      node.dataset.telegramPost = `${user}/${post}`;
      node.dataset.width = '100%';
      ref.current.appendChild(node);
    }, [post, user]);

    if (!user || !post) {
      return null;
    }

    return <div ref={ref} />;
  },
  (prev, next) => prev.post === next.post && prev.user === next.user,
);
