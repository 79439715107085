const REGEXP = /data-telegram-post="([\w/]+)"/i;

/**
 * Функция-парсер, извлекающая данные из html строчки с постом из телерамма.
 * Если данные извлечь не удалось - вовращает null.
 * @param text - html строчка с постом из телерамма.
 */
export const prepareDataForTelegram = (text: string) => {
  try {
    const data = text.match(REGEXP)?.[1];
    if (!data) return null;
    const [user, post] = data.split('/');
    if (!user || !post) return null;

    return { user, post };
  } catch (error) {
    console.error(error);

    return null;
  }
};
