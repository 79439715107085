const INST = 'instagram';
const BAN_COMMENT = '(организация запрещена в России)';

/**
 * Добавляет коммент о том что Inst запрещен в России
 * @param str - строка
 */
export const addInstBanComment = (str: string | undefined) => {
  if (!str) return '';

  return str.toLocaleLowerCase().includes(INST) ? `${str} ${BAN_COMMENT}` : str;
};

export const addInstBanCommentInHtmlString = (str: string) => {
  const instCapitalize = INST.charAt(0).toUpperCase() + INST.slice(1);

  return str.toLocaleLowerCase().includes(INST)
    ? str.replace(instCapitalize, `${instCapitalize} ${BAN_COMMENT}`)
    : str;
};
