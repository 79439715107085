const IMAGE_HEIGHT_16_9 = 720;
const IMAGE_HEIGHT_4_3 = 960;
const IMAGE_HEIGHT_1_1 = 1280;

export const IMAGE_WIDTH = 1280;

export const IMAGE_HEIGHTS: number[] = [
  IMAGE_HEIGHT_16_9,
  IMAGE_HEIGHT_4_3,
  IMAGE_HEIGHT_1_1,
];

export const IMAGE_CROP_STRINGS: { [key: number]: string } = {
  [IMAGE_HEIGHT_16_9]: `c${IMAGE_WIDTH}x${IMAGE_HEIGHT_16_9}o-x0i`,
  [IMAGE_HEIGHT_4_3]: `c${IMAGE_WIDTH}x${IMAGE_HEIGHT_4_3}o-x0i`,
  [IMAGE_HEIGHT_1_1]: `c${IMAGE_WIDTH}x${IMAGE_HEIGHT_1_1}o-x0i`,
};
