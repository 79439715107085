import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Head } from 'common/components/headers/Head';
import { NewsArticleSchema } from 'common/components/schemaOrg/NewsArticle';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { cropImage } from 'common/utils/crop';
import { s3Format, s3Image, s3Resize } from 'common/utils/s3';
import { MAX_VIDEO_PREVIEW } from 'config/constants/maxVideoPreview';
import { PROJECT_OG_IMG } from 'config/constants/projects/constants';
import { AUTO } from 'config/constants/s3ImageTypes';
import { TOPIC_ID_PODCAST } from 'config/constants/topics';
import { getShareImageUrl } from 'server/shareImageService/getShareImageUrl';
import { replaceLinks } from 'utils/replaceLinks';
import { textCroppingByWord } from 'utils/textCroppingByWord';

import { PreloadMainImage } from './PreloadMainImage';

const IMAGE_WIDTH = 600;
const IMAGE_HEIGHT = 315;
const CROP_STRING = `e${IMAGE_WIDTH}x${IMAGE_HEIGHT}i`;

type GetClusterImagePropsType = {
  imageUrl: string | undefined;
  isS3Image: boolean;
  projectAlias: ProjectType['alias'];
};

/**
 * Скопипастенная функция получения изображения для метаинфрмации кластера.
 * @param imageUrl - url изображения;
 * @param isS3Image - флаг, что это изображения из s3;
 * @param projectAlias - alias проекта.
 */
const getClusterImage = ({
  imageUrl,
  isS3Image,
  projectAlias,
}: GetClusterImagePropsType) => {
  if (!imageUrl) {
    return PROJECT_OG_IMG[projectAlias];
  }

  if (isS3Image) {
    return s3Image(imageUrl, [
      s3Resize({ number: 1, width: IMAGE_WIDTH, height: IMAGE_HEIGHT }),
      s3Format(2, AUTO),
    ]);
  }

  return cropImage(imageUrl, CROP_STRING);
};

type HeaderPropsType = {
  cluster: ClusterData | undefined;
  isMobile: boolean;
  top100PageType?: string;
};

/**
 * Метаинформация страницы кластера
 * @param props.cluster - данные об этом кластере
 * @param props.isMobile - версия приложения
 * @param props.top100PageType - топ100
 */
export const Header = memo(
  ({ cluster, isMobile, top100PageType = '' }: HeaderPropsType) => {
    const projectAlias = useSelector(selectProjectAlias);

    const {
      id: clusterId,
      annotation,
      image,
      title = '',
      url = '',
      longTitle,
      mainTopicId,
    } = cluster || {};

    const { pathname } = new URL(url);

    /**
     * video может быть пустым, поэтому проверяем наличие видео по наличию url.
     */
    const withMainVideo = !!cluster?.video.url;

    const clusterDescription = useMemo(
      () =>
        annotation
          ? textCroppingByWord({ str: replaceLinks(annotation || '') })
          : '',
      [annotation],
    );

    const shareImageUrl = getShareImageUrl({
      clusterId,
    });

    const clusterImage = getClusterImage({
      imageUrl: image?.url,
      isS3Image: !!image?.s3,
      projectAlias,
    });

    const isPodcast = mainTopicId === TOPIC_ID_PODCAST;

    return (
      <>
        {image && <PreloadMainImage image={image} />}
        <Head
          top100PageType={top100PageType}
          pageType="article"
          pageTitle={longTitle}
          pageDescription={clusterDescription}
          pageUrl={pathname as UrlType}
          ampUrl={`${pathname}amp/`}
          ramblerClid={`${cluster?.id}`}
          ogTitle={title}
          pubdate={cluster?.publicationTime}
          pageImage={shareImageUrl}
          isMobile={isMobile}
          maxImagePreview="large"
          maxVideoPreview={withMainVideo ? MAX_VIDEO_PREVIEW : undefined}
          isPodcast={isPodcast}
          clusterImage={clusterImage}
        />
        <NewsArticleSchema cluster={cluster} />
      </>
    );
  },
);
