export const NOT_ALLOWED_TAGS_INSIDE_PHRASING_CONTENT = [
  'ul',
  'ol',
  'p',
  'div',
  'blockquote',
  'address',
  'article',
  'aside',
  'command',
  'details',
  'dl',
  'fieldset',
  'figure',
  'footer',
  'form',
  'header',
  'hgroup',
  'hr',
  'keygen',
  'math',
  'menu',
  'nav',
  'pre',
  'section',
  'style',
  'scoped',
  'sup',
  'table',
  /*
  Уже обрабатываются отдельно
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  */
];

export const TOPIC_BUTTON_CLASS_NAME = 'article__reference-link';
