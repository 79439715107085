import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ImageObject, VideoObject, WithContext } from 'schema-dts';

import {
  selectDomainConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { domainGenerator } from 'utils/urlGenerator';

const PLAYER_URL = 'https://vp.rambler.ru/player/1.39.2/player.html#id=1987304';

/**
 * Хук для получения item для VideoObject
 * @param cluster - инфо о кластере.
 */
export const useItemForVideoObject = (cluster: ClusterData) => {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const projectId = useSelector(selectProjectId);

  const { annotation, video, longTitle, publicationTime } = cluster;

  const detailsImage = video?.preview;
  const detailsDuration = video?.duration;

  const domain = useMemo(
    () => domainGenerator(domainConfig, projectId),
    [projectId, domainConfig],
  );

  const noImageUrl = `${domain}/special/no_img.jpg`;
  const url = video?.url;

  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: longTitle,
    description: annotation,
    uploadDate: publicationTime,
    url,
    contentUrl: url,
    embedUrl: PLAYER_URL,
    isFamilyFriendly: 'True',
    thumbnail: (detailsImage || noImageUrl) as unknown as ImageObject,
    thumbnailUrl: detailsImage || noImageUrl,
    ...(detailsDuration && {
      duration: `T${Math.floor(detailsDuration / 1000)}S`,
    }),
  } as WithContext<VideoObject>;
};
