export enum CoreDraftBlockType {
  unstyled = 'unstyled',
  paragraph = 'paragraph',
  headerOne = 'header-one',
  headerTwo = 'header-two',
  headerThree = 'header-three',
  headerFour = 'header-four',
  headerFive = 'header-five',
  headerSix = 'header-six',
  unorderedListItem = 'unordered-list-item',
  orderedListItem = 'ordered-list-item',
  blockquote = 'blockquote',
  codeBlock = 'code-block',
  atomic = 'atomic',
}
