/**
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48184350
 * Получение массивов параграфов для вставки до и внутрь CUT.
 * Механика - Cut (укороченный) desktop
 * @param paragraphsList - массив параграфов-строк
 */
export const cutShort = <T>(
  paragraphsList: T[],
): {
  paragraphsListBeforeCut: T[];
  paragraphsListInCut: T[];
} => {
  let paragraphsListBeforeCut: T[] = [];
  let paragraphsListInCut: T[] = [];

  if (paragraphsList.length > 2) {
    paragraphsListBeforeCut = paragraphsList.slice(0, 2);
    paragraphsListInCut = paragraphsList.slice(2);
  } else {
    paragraphsListBeforeCut = [...paragraphsList];
  }

  return { paragraphsListBeforeCut, paragraphsListInCut };
};
