import { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectRuntime } from 'common/redux/runtime/selectors';
import { addSourceLinkToClipboard } from 'utils/modifyClipboardOnCopy';

/**
 * Вешает на ref copy-ивент и добавляет в буфер обмена ссылку на источник новости при
 * копировании текста больше 30 символов
 * NEWS-6496: Добавление ссылки на источник при копировании
 * @param cluster - объект данных о кластере
 * @param alias - алиас рубрики кластера
 */
export const useCopyModifier = <T extends HTMLElement>(
  cluster: ClusterData | undefined,
  alias?: string,
) => {
  const ref = useRef<T | null>(null);

  const runtime = useSelector(selectRuntime, shallowEqual);

  useEffect(() => {
    if (cluster) {
      addSourceLinkToClipboard({
        listenOn: ref.current,
        runtime,
        cluster,
        topicAlias: alias,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};
