import React from 'react';

import { BorderedQuote } from 'common/components/BorderedQuote';

import { ExpertInfo } from '../ExpertInfo';

type Props = {
  expertId: string | number;
  children: React.ReactNode;
};

/**
 * Базовый компонент верстки цитаты эксперта.
 * @param props - объект параметров компонента;
 * @param props.expertId - id эксперта, для которого рисуется цитата;
 * @param props.children - дочерний элемент цитаты.
 */
export const ExpertBase = ({ expertId, children }: Props) => (
  <BorderedQuote>
    {children}
    {expertId && <ExpertInfo expertId={Number(expertId)} />}
  </BorderedQuote>
);
