import { ExtendedMention, LinkMention } from './getCorrectMentions';

/**
 * Сортировка по офсету
 */
export const sortByOffset = (mentions: (ExtendedMention | LinkMention)[]) =>
  [...mentions].sort((m1, m2) => m1.offset - m2.offset);

/**
 * Регулярное выражение для удаления тега caption
 */
export const CAPTION_REGEXP =
  /\[((insert)(.)+?\]|caption(.)+?\](.)+?\[\/caption\])/g;

/**
 * Функция, удаляющая из тела кластера кусок разметки.
 * @param clusterBody - содержимое кластера.
 */
export const removeMarkupFromClusterBody = (clusterBody: ClusterData['body']) =>
  clusterBody.replace(CAPTION_REGEXP, '');
