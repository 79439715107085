import { Organization, Distance, ImageObject, Person } from 'schema-dts';

import {
  IMAGE_WIDTH,
  IMAGE_HEIGHTS,
} from 'common/components/schemaOrg/constants';
import { LOGOS_WIDTH } from 'config/constants/projects/constants';
import { RAMBLER_RESOURCES_IDS } from 'config/constants/ramblerResourcesIds';

/**
 * Получение publisher кластера для schema.org
 * @params project.id - id проекта
 * @params project.name - имя проекта ("Рамблер/новости", "Рамблер/авто", ...)
 * @params project.alias - alias проекта
 * @params domain - доменное имя
 * @returns - блок schema.org описывающий автора кластера
 */
export const getOrganization = (
  { id, name, alias }: ProjectType,
  domain: string,
): Organization => ({
  '@type': 'Organization',
  name,
  logo: {
    '@type': 'ImageObject',
    url: `${domain}/static/mobile/i/schema-logo/${alias}.png`,
    // Distance has incorrect type in 'schema-dts'
    width: LOGOS_WIDTH[id] as unknown as Distance,
    height: 60 as unknown as Distance,
  },
});

/**
 * Получение автора кластера для schema.org
 * @params domain - доменное имя
 * @params resource - для определения источника кластера
 * @params editorForCluster - инфо об авторах, чтобы найти автора нужного кластера
 * @params name - имя проекта ("Рамблер/новости", "Рамблер/авто", ...)
 * @returns - блок schema.org описывающий автора кластера,
 *            включает в себя блок для удобства разделения аналитики (агрегатор/рамблер)
 */
export const getAuthor = (
  domain: ProjectType['domain'],
  resourceData: Optional<ClusterResourceType>,
  editor: Optional<EditorType> | undefined,
  { name }: Runtime['project'],
): Organization | Person => {
  const isRamblerResource = RAMBLER_RESOURCES_IDS.includes(
    resourceData?.id ?? -1,
  );

  if (isRamblerResource && editor && editor.name) {
    return {
      '@type': 'Person',
      name: editor.name,
      url: `${domain}/editors/${editor.alias}/`,
    };
  }

  return {
    '@type': 'Organization',
    name: isRamblerResource ? name : resourceData?.title || '',
  };
};

/**
 * Получение ImageObject для schema.org
 * @params imgUrls - массив url изображений
 * @params name - длинный заголовок кластера
 * @params description - описание слайда или длинный заголовок кластера
 * @params authorThing - автор с типом Thing
 * @params isMain - указывает что слайд главный на странице
 * @params imageSource - ресурс изображения
 * @returns - блок schema.org описывающий ImageObject
 */
export const getImageObject = ({
  imgUrls,
  name,
  description,
  authorThing,
  isMain = true,
  imageSource,
}: {
  imgUrls: string[];
  name?: string;
  description?: string;
  authorThing?: string;
  isMain?: boolean;
  imageSource?: ClusterImageType['source']['title'];
}): ImageObject[] =>
  IMAGE_HEIGHTS.map(
    // @ts-expect-error: ¯\_(ツ)_/¯
    (cropHeight: number, index: number): ImageObject => ({
      '@type': 'ImageObject',
      name,
      representativeOfPage: isMain,
      url: imgUrls?.[index] || '',
      width: `${IMAGE_WIDTH}`,
      height: `${cropHeight}`,
      contentUrl: imgUrls?.[index] || '',
      description,
      ...(authorThing
        ? {
            author: {
              '@type': 'Thing',
              name: authorThing,
            },
          }
        : {}),
      ...(imageSource && !authorThing
        ? {
            author: {
              '@type': 'Organization',
              name: imageSource,
            },
          }
        : {}),
    }),
  );
