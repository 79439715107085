import React, { memo } from 'react';
import { VideoObject } from 'schema-dts';

import { JsonLd } from 'utils/jsond';

import { useItemForVideoObject } from './hooks';

type VideoObjectSchemaPropsType = {
  cluster: ClusterData;
};

/**
 * Получение VideoObject для schema.org
 * @param cluster - инфо о кластере.
 */
export const VideoObjectSchema = memo(
  ({ cluster }: VideoObjectSchemaPropsType) => {
    const { publicationTime } = cluster;

    const item = useItemForVideoObject(cluster);

    if (!!publicationTime && new Date(publicationTime) > new Date())
      return null;

    return <JsonLd<VideoObject> item={item} />;
  },
);
