import { useCallback, useEffect, useRef } from 'react';

import {
  checkIsEagleplatform,
  checkIsNewPlayer,
} from 'common/utils/checkIsVideoPlatform';

const TIME_OUT = 3000;
const LOADING_CLASS = '--loading';

/**
 * Удаление класса(--loading) с плейсхолдером для фото и видео в тексте кластера после их загрузки
 * @param html - текст параграфа
 */
export const useLoadedPlaceholder = <T extends HTMLElement>(html: string) => {
  const ref = useRef<T>(null);

  const removeLoadClass = useCallback(() => {
    const divWrapperNode = ref?.current?.firstElementChild;

    if (divWrapperNode) {
      divWrapperNode.className = divWrapperNode.className.replace(
        LOADING_CLASS,
        '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Обработка случая, если старый плеер от Eagleplatform
  const listenerOldPlayer = useCallback((event: MessageEvent) => {
    if (event.data.event === 'ready') {
      removeLoadClass();
      window.removeEventListener('message', listenerOldPlayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listener = useCallback((node: HTMLElement) => {
    removeLoadClass();
    node.removeEventListener('load', removeLoadClass);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (html && html.includes(LOADING_CLASS) && ref?.current) {
      const imageNode = ref.current.getElementsByTagName('img')?.[0];
      const videoNode = ref.current.getElementsByTagName('iframe')?.[0];
      const videoEagleplatformOld =
        videoNode && checkIsEagleplatform(videoNode.src);
      const videoEagleplatformNew =
        videoNode && checkIsNewPlayer(videoNode.src);

      if (imageNode) {
        imageNode.addEventListener('load', () => listener(imageNode));
      } else if (videoEagleplatformOld) {
        window.addEventListener('message', listenerOldPlayer);
      } else if (videoEagleplatformNew) {
        videoNode.addEventListener('load', () => listener(videoNode));
      }
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  // Убираем плейсхолдер через 3 сек, если колбэки не сработали
  useEffect(() => {
    setTimeout(() => {
      const divWrapperNode = ref?.current?.firstElementChild;

      if (divWrapperNode && divWrapperNode.className.includes(LOADING_CLASS)) {
        removeLoadClass();
      }
    }, TIME_OUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};
