import React, { memo } from 'react';

import { useVideoParams } from 'common/components/VideoPlayer/hooks/useVideoParams';
import { useCurrentCluster } from 'common/hooks/useCurrentCluster';
import { PuidsType } from 'config/constants/common';

import { ErrorBoundary } from '../ErrorBoundary';

import { VideoPlayerBody } from './components/VideoPlayerBody';
import {
  DockingDirection,
  DockingPosition,
} from './hooks/useLoadRamblerPlayer';

export const VIDEO_DATA_TYPE = {
  broadcast: 7,
  common: 6,
};

type VideoPlayerPropsType = {
  embedCode: string | undefined;
  puids: PuidsType | undefined;
  type?: 'default' | 'multi';
  styles?: StylesType;
  playerId: string;
  needMiniPlayer?: boolean;
  miniPlayerStopRef?: React.MutableRefObject<HTMLElement | null>;
  isBroadcast: boolean;
  height?: number;
  docking?: DockingPosition;
  dockingDirection?: DockingDirection;
  clusterId: CardData['id'];
  clusterUrl: string;
  isAd?: boolean;
};

/** https://jira.rambler-co.ru/browse/NEWS-7927 - основная задача */
/** https://jira.rambler-co.ru/browse/NEWS-8446 - задача из-за которой закомментирован код */
// const DO_DIR1_TOPIC_ID = [465, 151, 153, 145, 144, 150, 154, 143, 147, 148, 157, 160, 158, 146, 159, 152, 156, 226, 219, 240, 504];

/**
 * Компонент RamblerPlayer для видео от EaglePlatform
 * https://vp.rambler.ru/docs/player/index.html
 * @param embedCode - html-код видео плеера
 * @param puids - рекламные пуиды
 * @param styles - дополнительные стили
 * @param playerId - id node-узла, в который вставляется плеер
 * @param isBroadcast - флаг, что это видеотрансляция
 * @param height - фиксированная высота видео
 * @param docking - положение мини-плеера
 * @param dockingDirection - разрешенное направление для отображения миниплеера (только снизу от плеера или в обе стороны);
 * @param clusterId - id кластера, которому принадлежит плеер
 * @param clusterUrl - URL кластера, в который встроен плеер
 * @param isAd - флаг, что это рекламное видео
 */
export const VideoPlayer = memo(
  ({
    embedCode,
    puids,
    playerId,
    styles,
    isBroadcast = false,
    height,
    docking,
    dockingDirection,
    clusterId,
    clusterUrl,
    isAd = false,
  }: VideoPlayerPropsType) => {
    // Не проставляем ad_template_id для данных рубрик
    // https://jira.rambler-co.ru/browse/NEWS-8446

    const {
      recordId,
      multiplayerFilterId,
      playerTemplateId,
      sspJparams,
      previewComment,
    } = useVideoParams(embedCode, puids, isAd);

    const { isCurrentCluster } = useCurrentCluster(String(clusterId));

    return (
      <ErrorBoundary componentName="VideoPlayerBody">
        <VideoPlayerBody
          clusterUrl={clusterUrl}
          docking={docking}
          dockingDirection={dockingDirection}
          playerId={playerId}
          recordId={recordId}
          multiplayerFilterId={multiplayerFilterId}
          playerTemplateId={playerTemplateId}
          sspJparams={sspJparams}
          previewComment={previewComment}
          height={height}
          isLive={isBroadcast}
          isCurrentCluster={isCurrentCluster}
          styles={styles}
        />
      </ErrorBoundary>
    );
  },
);
