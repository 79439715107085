type GenerateLinkType = {
  href: string;
  readableTagType: string;
  text: string;
  top100AttributeName?: string;
};

/**
 * Создает тег ссылки
 * @param href - урл ссылки
 * @param readableTagType - тип тега
 * @param text - текст ссылки
 * @param top100AttributeName - название атрибута топ100 в зависимости от дизайна
 */
export const generateLink = ({
  href,
  readableTagType,
  text,
}: GenerateLinkType): string => {
  const className = `j-article-text-tag j-article-text-tag-${readableTagType}`;

  return `<span data-blocks="tags_type"><a data-article-text-tag href="${href}" class="${className} biqpf-autotag" data-blocks="${readableTagType}" target="_blank">${text}</a></span>`;
};
