import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuizEmbed } from 'amp/pages/ClusterAmp/components/QuizEmbed';
import { MetaMaxVideoPreview } from 'common/components/MetaMaxVideoPreview';
import { selectPageName } from 'common/redux/appController/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';

import { BAN_EMBEDS, EMBED_TYPE, VIDEO_EMBEDS } from '../../config';

import {
  SpecialEmbedResolver,
  SPECIAL_EMBEDS,
} from './components/SpecialEmbedResolver';
import { useLoadedPlaceholder } from './useLoadedPlaceholder';

const PAGE_CLUSTER_EMBED_SET = 'PAGE_CLUSTER_EMBED_SET';

type EmbedParagraphPropsType = {
  text: string;
  config: ContentConfig | undefined;
  clusterIndex: number;
  clusterUrl: string;
  containerClass?: string;
};

/**
 * Параграф с эмбедом.
 * @param text - текст параграфа;
 * @param config - объект конфигурации эмбеда;
 * @param clusterIndex - индекс кластера в списке кластеров;
 * @param clusterUrl - url кластера;
 * @param containerClass - класс стилей для обертки эмбеда.
 */
export const EmbedParagraph = memo(
  ({
    text,
    config,
    clusterIndex,
    clusterUrl,
    containerClass = '',
  }: EmbedParagraphPropsType) => {
    const dispatch = useDispatch();

    const pageName = useSelector(selectPageName);

    const { type, onReload, width, height } = config as EmbedConfigType;

    const isVideoEmbed = VIDEO_EMBEDS.some((videoEmbed) => type === videoEmbed);
    const isBanEmbed = BAN_EMBEDS.some((banType) => type === banType);

    const loadEmbed = useCallback(
      (embedType: number) =>
        dispatch({
          type: PAGE_CLUSTER_EMBED_SET,
          payload: { data: embedType },
        }),
      [dispatch],
    );

    const modifiedText = useMemo(() => {
      if (type === EMBED_TYPE.QUIZ && clusterUrl) {
        const textWithQuizUrl = text.replace(
          /data-quiz-id="/gi,
          `data-quiz-page-url=${clusterUrl}\ndata-quiz-id="`,
        );

        // Меняем цвет у разметки квизов
        return textWithQuizUrl.replace(
          /data-quiz-color="(#9B20CC|#fc2772|#c60d8b)"/gi,
          'data-quiz-color="#315efb"',
        );
      }

      if (type === EMBED_TYPE.VP_RAMBLER || type === EMBED_TYPE.EAGLEPLATFORM) {
        return text.replace(
          /data-widget="Player"/g,
          'data-widget="Player" data-docking="false"',
        );
      }

      return text;
    }, [clusterUrl, text, type]);

    // Данная логика применяется только к embeds с onReload
    useEffect(() => {
      if (!isBanEmbed && onReload) {
        if (clusterIndex === 0) {
          loadEmbed(type);
        } else {
          onReload();
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // только для кода который вставляется через dangerouslySetInnerHTML
      // тк скрипты таким способом вставки не запускаются при подгрузке
      // https://macarthur.me/posts/script-tags-in-react#the-problem-script-tags-dont-run
      const isForceRunScript =
        modifiedText &&
        !isBanEmbed &&
        pageName !== PAGE_TYPE.clusterAmp &&
        !SPECIAL_EMBEDS.includes(type);

      if (!ref.current || !isForceRunScript) return;

      const range = document.createRange();

      range.selectNode(ref.current);

      const documentFragment = range.createContextualFragment(modifiedText);

      ref.current.innerHTML = '';
      ref.current.append(documentFragment);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { ref, isLoaded, placeholder } = useLoadedPlaceholder<HTMLDivElement>(
      {
        width,
        height,
        type,
      },
    );

    if (isBanEmbed) return null;

    if (pageName === PAGE_TYPE.clusterAmp && type === EMBED_TYPE.QUIZ) {
      return <QuizEmbed text={text} canonicalUrl={clusterUrl} />;
    }

    if (SPECIAL_EMBEDS.includes(type)) {
      return (
        <div ref={ref}>
          <SpecialEmbedResolver text={modifiedText} embedType={type} />
        </div>
      );
    }

    return (
      <>
        {isVideoEmbed && <MetaMaxVideoPreview />}
        <div
          className={containerClass}
          ref={ref}
          style={
            !isLoaded
              ? {
                  display: 'none',
                }
              : {}
          }
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: !isBanEmbed ? modifiedText : '' }}
        />
        {!isBanEmbed && placeholder}
      </>
    );
  },
  () => true,
);
