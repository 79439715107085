import { ImageObject, WithContext } from 'schema-dts';

import { getImageObject } from '../../modules';

type GetItemForImageObjectType = {
  imgUrls: string[];
  name: string | undefined;
  description: string | undefined;
  imageSource: string;
  isMain?: boolean;
};
/**
 * Функция для получения item для ImageObject
 * @params imgUrls - урлы изображения;
 * @params name - длиный заголовок кластера;
 * @params description - описание слайда или длинный заголовок кластера;
 * @params imageSource - название источника;
 * @params isMain - указывает что слайд главный на странице.
 */
export const getItemForImageObject = ({
  imgUrls,
  name = '',
  description = '',
  imageSource,
  isMain = true,
}: GetItemForImageObjectType) =>
  ({
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    image: getImageObject({
      imgUrls,
      name,
      description,
      imageSource,
      isMain,
    }),
  }) as WithContext<ImageObject>;
