const VK_REGEXP = /div id="([\w\W]+?)"/i;
const VK_TOKEN_REGEXP = /VK.Widgets.Post\([\W\w\d]+,[\W\w\d]+'([\w\W]+)'/i;

/**
 * Функция-парсер, извлекающая данные из html строчки с постом вк.
 * Если данные извлечь не удалось - вовращает null.
 * @param text - html строчка с постом vk.
 */
export const prepareDataForVk = (text: string) => {
  try {
    const data = text.match(VK_REGEXP)?.[1];
    if (!data) return null;
    const [, , user, post] = data.split('_');
    if (!user || !post) return null;
    const token = text.match(VK_TOKEN_REGEXP)?.[1];
    if (!token) return null;

    return { token, user, post };
  } catch (error) {
    console.error(error);

    return null;
  }
};
