import React, { memo } from 'react';

// заменила тк Link из react-router-dom работал нестабилльно
// если при скролле урл сменился на следующий кластер а открываем теги из предыдущего,
// открывалась страница без данных, либо страница кластера вместо новостей рубрики
import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import { createMarkerUrl } from '../../utils/createMarkerUrl';

type TagLinkPropsType = MarkerType & {
  position: number;
  styles: StylesType;
};

/**
 * Компонент ссылки для тэгов. Защищает от роутинга на ещё не переписанные страницы.
 * @param name - текст ссылки страницы;
 * @param position - позиция страницы;
 * @param type - тип страницы;
 * @param alias - алиас страницы, на которую будет совершен переход;
 * @param id - id страницы;
 * @param styles - стили компонента.
 */
export const TagLink = memo(
  ({ name, id, type, alias, position, styles }: TagLinkPropsType) => {
    const top100Attribute = useTop100AttributeWithValue(type || alias);
    const label = `Перейти к новостям по теме ${name}`;

    const href = createMarkerUrl({ id, type, alias });

    // пример https://finance.rambler.ru/markets/47651056-kurs-rublya-ruhnul/
    if (type === 'tag') {
      return (
        <a
          href={href}
          aria-label={label}
          data-index={position}
          data-test="tag-link"
          className={styles?.tagListItem}
          {...top100Attribute}
        >
          {name}
        </a>
      );
    }

    return (
      <Link
        href={href}
        ariaLabel={label}
        data-index={position}
        data-test="tag-link"
        forceSPA
        className={styles?.tagListItem}
        {...top100Attribute}
      >
        {name}
      </Link>
    );
  },
);
