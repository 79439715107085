// @ts-expect-error: ¯\_(ツ)_/¯
import trunc from 'trunc-html';

import { PARAGRAPH_TYPE } from '../../config';
import { ParagraphConfigType } from '../../typings';

export const DEFAULT_MAX_ARTICLE_LENGTH = 800;
export const SMALL_ARTICLE_MAX_LENGTH_PROPORTION = 2 / 3;

/**
 * обрезка html-строки
 * по заданному количеству символов
 * с отбрасыванием последнего неполного предложения
 * @param text - html-строка
 * @param limit - максимальное количество символов
 */
const cutTextOnSentence = (text: string, limit: number): string => {
  const cuttedText: string = trunc(text, limit).html;

  const sentenceSplitRegExp = /([!|?|.](?=\s))/g;
  const sentences = cuttedText.split(sentenceSplitRegExp);

  sentences.pop();

  if (sentences.length === 0) {
    return '';
  }

  return sentences.join('');
};

/**
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=85819467
 * @param paragraphsList - массив параграфов-строк
 */
export const cutResource = (
  paragraphsList: ParagraphConfigType[],
): {
  paragraphsListBeforeCut: ParagraphConfigType[];
  paragraphsListInCut: ParagraphConfigType[];
} => {
  const textParagraphs = paragraphsList.filter(({ type, text }) => {
    if (type === PARAGRAPH_TYPE.EMBED) {
      return false;
    }

    return text.trim().length > 0;
  });

  if (!textParagraphs.length) {
    return { paragraphsListBeforeCut: [], paragraphsListInCut: [] };
  }

  // если статья меньше 800 символов, то берем 2/3 от ее длины
  const articleLength =
    textParagraphs[textParagraphs.length - 1].accumulatedLength;
  const maxArticleLength =
    articleLength < DEFAULT_MAX_ARTICLE_LENGTH
      ? Math.round(articleLength * SMALL_ARTICLE_MAX_LENGTH_PROPORTION)
      : DEFAULT_MAX_ARTICLE_LENGTH;

  const paragraphsListBeforeCut: ParagraphConfigType[] = [];

  for (let i = 0; i < textParagraphs.length; i += 1) {
    const paragraph = textParagraphs[i];
    const { accumulatedLength, paragraphLength, text, type } = paragraph;

    // Цитату нужно не обрезать, т.к. ссылки внутри цитаты криво обрезаются NEWS-10086
    const isQuote = type === PARAGRAPH_TYPE.INCREMENT_TAG;

    if (isQuote || accumulatedLength < maxArticleLength) {
      paragraphsListBeforeCut.push(paragraph);
    } else {
      const lengthLimit =
        paragraphLength - (accumulatedLength - maxArticleLength);
      const cuttedText = cutTextOnSentence(text, lengthLimit);

      if (!cuttedText) {
        break;
      }

      const cuttedParagraph = {
        ...paragraph,
        text: cuttedText,
        paragraphLength: lengthLimit,
        accumulatedLength: maxArticleLength,
      };

      paragraphsListBeforeCut.push(cuttedParagraph);

      break;
    }
  }

  if (!paragraphsListBeforeCut.length) {
    return { paragraphsListBeforeCut: textParagraphs, paragraphsListInCut: [] };
  }

  const isHeaderAsParagraph = (text: string): boolean =>
    text.endsWith('</strong>');
  const getLastParagraph = () =>
    paragraphsListBeforeCut[paragraphsListBeforeCut.length - 1];

  while (
    (isHeaderAsParagraph(getLastParagraph().text) ||
      getLastParagraph().type === PARAGRAPH_TYPE.NO_INCREMENT_TAG) &&
    paragraphsListBeforeCut.length > 1
  ) {
    paragraphsListBeforeCut.pop();
  }

  return { paragraphsListBeforeCut, paragraphsListInCut: [] };
};
