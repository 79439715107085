/**
 * Проверка, что в тексте есть видео от eagleplatform (старое название видеоплатформы)
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=53805746
 * @param text - текст параграфа или src плеера
 */
export const checkIsEagleplatform = (text: string = ''): boolean =>
  text.includes('eagleplatform');

/**
 * Проверка, что в тексте есть видео платформы
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=53805746
 * @param text - текст параграфа или src плеера
 */
export const checkIsNewPlayer = (text: string = ''): boolean =>
  text.includes('vp.rambler.ru/player');
