import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

/**
 * Хук отдающий флаг, что текущий url совпадает с текущим кластером
 * @param clusterID - id кластера
 */
export const useCurrentCluster = (clusterID: CardData['id'] | string) => {
  const { clusterId: routerClusterID }: { clusterId: string } = useParams();

  const [isCurrentCluster, setIsCurrentCluster] = useState<boolean>(
    routerClusterID === String(clusterID),
  );

  useEffect(() => {
    setIsCurrentCluster(routerClusterID === clusterID);
  }, [clusterID, routerClusterID]);

  return { isCurrentCluster };
};
