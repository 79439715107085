import React, { memo } from 'react';
import { ImageObject } from 'schema-dts';

import { JsonLd } from 'utils/jsond';

import { useGetImageSrc } from '../../hooks';
import { getItemForImageObject } from '../utils';

type ClusterImageObjectPropsType = {
  cluster: ClusterData;
};

/**
 * Получение ImageObject schema.org для картинки кластера
 * @params cluster - инфо о кластере
 * @returns - блок schema.org описывающий ImageObject
 */
export const ClusterImageObject = memo(
  ({ cluster }: ClusterImageObjectPropsType) => {
    const { image, longTitle, publicationTime } = cluster;
    const url = image?.url || '';
    const s3 = image?.s3 || false;
    const imageSource = image?.source?.title || '';
    const pageImages = useGetImageSrc(url, s3);

    const item = getItemForImageObject({
      imgUrls: pageImages,
      name: longTitle,
      description: longTitle,
      imageSource,
    });

    if (!!publicationTime && new Date(publicationTime) > new Date())
      return null;

    return <JsonLd<ImageObject> item={item} />;
  },
);
