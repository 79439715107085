import { AUTOTAG_TYPE } from 'config/constants/cluster';

type PropsType = {
  id?: MarkerType['id'];
  type?: MarkerType['type'];
  alias: MarkerType['alias'];
};

/**
 * Функция, возвращающая url маркера в зависимости от его типа.
 * @param id - id маркера
 * @param type - тип маркеров
 * @param alias - alias маркера
 */
export const createMarkerUrl = ({ id, type, alias }: PropsType) => {
  switch (type) {
    case 'theme': {
      return `/theme/${id}-${alias}/`;
    }

    case AUTOTAG_TYPE.media: {
      return `/media/${alias}/`;
    }

    case AUTOTAG_TYPE.organization: {
      return `/organization/${alias}/`;
    }

    case AUTOTAG_TYPE.person: {
      return `/person/${alias}/`;
    }

    case 'tag': {
      return `/tag/${alias}/`;
    }

    case AUTOTAG_TYPE.auto: {
      return `/auto/${alias}/`;
    }

    case AUTOTAG_TYPE.movie: {
      return `/movie/${alias}/`;
    }

    case AUTOTAG_TYPE.region: {
      return alias === 'Moscow' ? '/moscow_city/' : `/${alias}/`;
    }

    default:
      return `/${alias}/`;
  }
};
