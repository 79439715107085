import React, { memo, useEffect, useRef } from 'react';

export type VkPropsType = {
  user: string;
  post: string;
  token: string;
};

/**
 * Компонент эмбеда вк.
 * @param token - ссылка, на которую указывает компонент;
 * @param user - id юзера, опубликовавшем пост;
 * @param post - id поста.
 */
export const Vk = memo(
  ({ user, post, token }: VkPropsType) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!ref.current || !user || !post) {
        return () => {};
      }

      // Обертка для вставки поста
      const div = document.createElement('div');

      div.id = `vk_post_${user}_${post}`;

      // Скрипт, подгружающий библиотеку
      const apiNode = document.createElement('script');

      apiNode.src = 'https://vk.com/js/api/openapi.js?169';
      apiNode.async = false;

      // Скрипт, отрисовывающий пост
      const node = document.createElement('script');

      node.innerHTML = `(function() { VK.Widgets.Post("vk_post_${user}_${post}", ${user}, ${post}, '${token}')}());`;

      const cb = () => ref.current?.appendChild(node);

      // Рисуем последний скрипт только после загрузки либы
      apiNode.addEventListener('load', cb);

      ref.current.appendChild(div);
      ref.current.appendChild(apiNode);

      return () => {
        apiNode.removeEventListener('load', cb);
        div.remove();
        apiNode.remove();
      };
    }, [post, token, user]);

    if (!user || !post) {
      return null;
    }

    return <div ref={ref} />;
  },
  (prev, next) =>
    prev.post === next.post &&
    prev.user === next.user &&
    prev.token === next.token,
);
