import React, { memo } from 'react';

import { BorderedQuote } from 'common/components/BorderedQuote';
import { ExpertQuote } from 'common/components/ExpertQuote';
import { TAG_TYPE } from 'common/utils/clusterContent/config';

import { TextParagraph } from '../TextParagraph';

type TagParagraphPropsType = {
  text: string;
  config: ContentConfig | undefined;
};

/**
 * Параграф с тегом
 * @param text - текст параграфа
 * @param config - объект конфигурации тэга
 */
export const TagParagraph = memo(({ text, config }: TagParagraphPropsType) => {
  const { type, replace } = config as TagConfigType;
  const html = replace?.(text) || '';

  switch (type) {
    case TAG_TYPE.BLOCKQOUTE:
      return (
        <BorderedQuote>
          <p dangerouslySetInnerHTML={{ __html: html }} />
        </BorderedQuote>
      );
    case TAG_TYPE.EXPERT_BLOCKQUOTE:
      return <ExpertQuote>{html}</ExpertQuote>;
    case TAG_TYPE.H1:
      return (
        <h1
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H2:
      return (
        <h2
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H3:
      return (
        <h3
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H4:
      return (
        <h4
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.DIV:
      return (
        <div
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );

    default:
      return <TextParagraph text={html} />;
  }
});
