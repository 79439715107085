type PropsType = {
  clusterId?: ClusterData['id'] | null;
};
type BuildUrlType = (props: PropsType) => string;

const SHARE_IMAGE_URL = 'https://img-renderer.rambler.ru/api/generate/media/';
const DEFAULT_FORMAT = 'png';

/**
 * Получение урла кастомной картинки шаринга
 * @param props.clusterId - id кластера
 */
export const getShareImageUrl: BuildUrlType = ({ clusterId }) => {
  if (!clusterId) return '';

  const searchParams = new URLSearchParams();

  searchParams.append('clusterId', clusterId);
  searchParams.append('format', DEFAULT_FORMAT);

  return `${SHARE_IMAGE_URL}?${searchParams.toString()}`;
};
