import { ParagraphConfigType } from './typings';

const OLD_TOP100_MARKUP_REGEXP = /data-blocks/g;

/**
 * Метод подмены всех жестко созданных ссылок на новые.
 * Умрет, когда доедет драфт.
 * @param paragraphs - список распаршенных параграфов;
 * @param top100AttributeName - название аттрибута, на который будет происходит замена.
 */
export const replaceOldTop100Markup = (
  paragraphs: ParagraphConfigType[],
  top100AttributeName: string,
) =>
  paragraphs.map((config) => ({
    ...config,
    text: config.text.replace(OLD_TOP100_MARKUP_REGEXP, top100AttributeName),
  }));
