import React, { memo, useMemo } from 'react';

import { DATA_EXPERT_REG } from 'common/redux/commonData/experts/utils';

import { ExpertBase } from './ExpertBase';

type ExpertQuotePropsType = {
  children: string;
};

/**
 * Компонент цитаты эсперта для вставки в статью
 * @param children - html с цитатой и id эксперта
 */
export const ExpertQuote = memo(({ children }: ExpertQuotePropsType) => {
  const { expertId, expertQuote } = useMemo(
    () => ({
      expertQuote:
        children.match(/<blockquote.*?>(.*?)<\/blockquote>/is)?.[1] || '',
      expertId: children.match(DATA_EXPERT_REG)?.[1],
    }),
    [children],
  );

  if (!children) return null;

  return expertId ? (
    <ExpertBase expertId={expertId}>
      <p
        dangerouslySetInnerHTML={{ __html: expertId ? expertQuote : children }}
      />
    </ExpertBase>
  ) : null;
});
