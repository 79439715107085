import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { BlockquoteIcon } from 'icons';

import s from './styles.module.css';

type BorderedQuotePropsType = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * В конце цитат могут стоять переносы изза которых отступ кажется конским, поэтому тримим последний абзац
 * @param blocks – массив абзацев, children передаваемые в цитату
 */
const trimBlocks = (blocks: React.ReactNode): React.ReactNode => {
  if (!Array.isArray(blocks)) return blocks;

  const lastBlock = blocks[blocks.length - 1];

  if (Array.isArray(lastBlock)) {
    return [blocks.slice(0, -1), trimBlocks(lastBlock)];
  }

  if (typeof lastBlock === 'string') {
    // обрезаем только перенос в конце цитаты тк иначе могут удаляться пробелы
    // между обычным текстом и ссылками внутри цитаты, а также индекс автотегов может смещаться
    return [blocks.slice(0, -1), lastBlock.trimEnd()];
  }

  return blocks;
};

/**
 * Компонент цитаты с градиентной границей
 * @param props - объект с настройками компонента.
 * @param props.className - строчка с классом для дополнительной стилизации.
 */
export const BorderedQuote = memo(
  ({ children, className }: BorderedQuotePropsType) => {
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const isMobile = useSelector(selectIsMobile);
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    return (
      <div
        className={cn(
          s.container,
          isMobile && s.mobile,
          forceRedesign && s.redesign,
        )}
      >
        <BlockquoteIcon className={cn(s.icon, isMobile && s.mobile)} />
        <blockquote
          className={cn(
            s.content,
            className,
            s[`content_${textScaleValue}`],
            forceRedesign && s.redesign,
          )}
        >
          {trimBlocks(children)}
        </blockquote>
      </div>
    );
  },
);
