import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectClusterById,
  selectClusterMainTopic,
} from 'common/redux/commonData/entries/selectors';
import {
  selectIsBannedCommentsTopic,
  selectDomainConfig,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import { generateClusterUrl } from 'utils/urlGenerator';

import s from './styles.module.css';

type CommentsButtonPropsType = {
  className?: string;
  countClassName?: string;
  top100?: string;
  forceSPA?: boolean;
  clusterId: ClusterData['id'] | null;
};

/**
 * Список комментариев кластера
 * @param clusterId - id кластера
 * @param className - кастомный класс кнопки
 * @param countClassName - кастомный класс счетчика
 * @param top100 - кастомный аттрибут для top100
 * @param forceSPA - флаг SPA перехода
 */
export const CommentsButton = memo(
  ({
    clusterId,
    className = '',
    countClassName = '',
    top100 = 'comments_button',
    forceSPA = false,
  }: CommentsButtonPropsType) => {
    const currentCluster = useSelector(
      selectClusterById(clusterId),
      shallowEqual,
    );
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const topic = useSelector(selectClusterMainTopic(clusterId!), shallowEqual);
    const isBannedComments = useSelector(
      selectIsBannedCommentsTopic(topic?.alias),
    );
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const top100Attribute = useTop100AttributeWithValue(top100);

    const href = `${generateClusterUrl({
      clusterId: currentCluster?.id,
      normalizedTitle: currentCluster?.normalizedTitle,
      domainConfig,
      topic,
      addDomain: false,
    })}comments/`;

    const isCancelRender =
      isBannedComments || !currentCluster || currentCluster?.noComments;

    if (isCancelRender) return null;

    return (
      <Link
        ariaLabel="Открыть комментарии"
        className={cn(s.commentsButton, className)}
        href={href}
        rel="nofollow"
        {...top100Attribute}
        forceSPA={forceSPA}
      >
        <CommentsCounter
          count={currentCluster?.commentsCount}
          className={cn(s.iconContainer, countClassName)}
          iconId={forceRedesign ? 'icon-comments-rounded' : 'icon-dialog'}
          showIcon
        />
      </Link>
    );
  },
);
