type GetKeyFnType<T> = (
  value: T,
) => string | number | boolean | undefined | null;

/**
 * Возращает новый массив с уникальными элементами по заданному функцией ключу.
 * (Если нет функции, ключ - сам элемент массива)
 * Берется первое уникальное значение. Работает со всеми типами данных
 *
 * Использование: `uniqueBy(persons, person => person.city.id)`
 * @param arr Массив для фильтрации
 * @param getKey Функция для получения ключа элемента массива `(person => person.city.id)`
 */
export const uniqueBy = <T>(arr: T[], getKey?: GetKeyFnType<T>): T[] => {
  if (!arr.length) return arr;

  let hasNull = false;
  let hasUndefined = false;
  let hasFalse = false;
  let hasTrue = false;
  const keys: Record<string | number, T> = {};

  const result: T[] = [];

  arr.forEach((value) => {
    const key = getKey ? getKey(value) : value;

    if (key === false) {
      if (!hasFalse) {
        result.push(value);
        hasFalse = true;
      }

      return;
    }

    if (key === true) {
      if (!hasTrue) {
        result.push(value);
        hasTrue = true;
      }

      return;
    }

    if (key === null) {
      if (!hasNull) {
        result.push(value);
        hasNull = true;
      }

      return;
    }

    if (key === undefined) {
      if (!hasUndefined) {
        hasUndefined = true;
        result.push(value);
      }

      return;
    }

    const strKey = String(key);

    if (!keys[strKey]) {
      keys[strKey] = value;
      result.push(value);
    }
  });

  return result;
};
