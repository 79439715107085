/**
 * Разбивает текст на параграфы
 * @param text - текст, который нужно разбить на параграфы
 * @param separator - разделитель, по которому будет разбиваться текст
 */
export const splitByParagraphs = (
  text: string,
  separator: string = '\n\n',
): string[] => {
  if (!text) return [];

  return text.split(separator);
};
