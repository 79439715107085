import { ParagraphConfigType } from './typings';

const SPLIT_SYMBOL = '<a ';

const CLASSNAME_TAG = 'j-article-text-tag';
const CLASSNAME_LINK = 'j-article-text-link';

const TARGET_SELF = '_self';
const TARGET_BLANK = '_blank';

/**
 * Метод подмены значения аттрибута target, внешние ссылки _blank, ссылки на теги _self.
 * @param paragraphs - список распаршенных параграфов.
 */
export const replaceLinkTarget = (paragraphs: ParagraphConfigType[]) =>
  paragraphs.map((paragraph) => {
    const text = paragraph?.text
      ?.split(SPLIT_SYMBOL)
      .map((str) => {
        if (str.includes(CLASSNAME_TAG)) {
          return str.replace(TARGET_BLANK, TARGET_SELF);
        }

        if (str.includes(CLASSNAME_LINK)) {
          return str.replace(TARGET_SELF, TARGET_BLANK);
        }

        return str;
      })
      .join(SPLIT_SYMBOL);

    return {
      ...paragraph,
      text,
    };
  });
