import cn from 'classnames';
import React, { memo, useEffect, useRef } from 'react';

import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import {
  DockingPosition,
  DockingDirection,
  useLoadRamblerPlayer,
} from '../../hooks/useLoadRamblerPlayer';

type BodyPropsType = {
  clusterUrl: string;
  playerId: string;
  recordId: number | string;
  playerTemplateId: number | string | undefined;
  sspJparams: PuidsType | string | undefined;
  multiplayerFilterId: number | string | undefined;
  previewComment?: unknown; // Неизвестно, что это за параметр и для чего он
  docking?: DockingPosition;
  dockingDirection?: DockingDirection;
  isLive: boolean;
  isCurrentCluster: boolean;
  height?: number;
  styles?: StylesType;
};

/**
 * Тело видеоплеера. Принимает обобщенные данные, чтобы плеер можно было запускать в разных окружениях.
 * @param props - параметры компонента;
 * @param props.clusterUrl - url кластера, внутри которого рисуется плеер;
 * @param props.playerId - id node-узла, в который вставляется плеер;
 * @param props.recordId - id видеозаписи;
 * @param props.playerTemplateId - id шаблона для отображения плеера (например, с рекоммендациями или нет);
 * @param props.sspJparams - рекламные настройки для плеера;
 * @param props.multiplayerFilterId - id шаблона для отображения рекоммендаций и их поведения;
 * @param props.previewComment - неизвестный параметр;
 * @param props.docking - положение мини-плеера;
 * @param props.dockingDirection - разрешенное направление для отображения миниплеера;
 * @param props.isLive - флаг, что это видеотрансляция;
 * @param props.height - фиксированная высота видео;
 * @param props.isCurrentCluster - флаг, что видео расположено в первом кластере;
 * @param props.styles - дополнительные стили.
 */
export const VideoPlayerBody = memo(
  ({
    clusterUrl,
    playerId,
    recordId,
    playerTemplateId,
    sspJparams,
    multiplayerFilterId,
    previewComment,
    docking,
    dockingDirection,
    isLive,
    height,
    isCurrentCluster,
    styles,
  }: BodyPropsType) => {
    const playerRef = useRef<RamblerPlayer | null>(null);
    const forceRedesign = useAppSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    useLoadRamblerPlayer({
      clusterUrl,
      playerId,
      recordId,
      playerRef,
      playerTemplateId,
      sspJparams,
      multiplayerFilterId,
      isLive,
      height,
      docking,
      dockingDirection,
      shouldDrawPlayer: Boolean(
        isCurrentCluster && !playerRef.current && recordId,
      ),
    });

    const className = styles
      ? cn(
          styles.player,
          forceRedesign && styles.playerRedesign,
          { [styles['player--loading']]: !playerRef.current },
          { [styles.multiplayer]: multiplayerFilterId && styles.multiplayer },
        )
      : '';

    const previewCommentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (previewCommentRef.current && previewComment) {
        previewCommentRef.current.outerHTML = previewComment as string;
      }
    }, [previewComment]);

    const playerBlock = (
      <>
        <div id={playerId} className={className} />
        <div ref={previewCommentRef} />
      </>
    );

    if (!recordId) return null;

    return playerBlock;
  },
);
