import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import {
  IMAGE_TYPES,
  IMAGES_SIZES_DIR,
} from 'common/components/headers/PreloadMainImage/config';
import { cropImage, makeOrientationCrop, CROP_TYPES } from 'common/utils/crop';
import { s3Image, s3Resize, s3Format } from 'common/utils/s3';
import { AUTO } from 'config/constants/s3ImageTypes';
import { generateCrop } from 'utils/generateSrcsets';

type PreloadMainImagePropsType = {
  image: ClusterData['image'];
};

/**
 * Добавляет тег предзагрузки главной картинки
 * @param imageUrl - url изображения кластера.
 */
export const PreloadMainImage = memo(({ image }: PreloadMainImagePropsType) => {
  if (!image.url) return null;

  const preloadImagesUrls = IMAGES_SIZES_DIR[IMAGE_TYPES.cluster].desktop.map(
    ({ width, height }: { width: number; height: number }) => {
      const s3Url = s3Image(image.url || '', [
        s3Resize({ number: 1, width, height }),
        s3Format(2, AUTO),
      ]);

      const cropValue = makeOrientationCrop({
        width,
        height,
        image,
        verticalType: CROP_TYPES.cTypeO,
        horizontalType: CROP_TYPES.cType,
      });

      return image.s3 ? s3Url : cropImage(image.url, generateCrop(cropValue));
    },
  );

  return (
    <Helmet>
      {preloadImagesUrls.map((url: string) => (
        <link key={url} rel="preload" href={url} as="image" />
      ))}
    </Helmet>
  );
});
