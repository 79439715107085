/** Тип параграфа для рендера */
export enum PARAGRAPH_TYPE {
  TEXT,
  INCREMENT_TAG,
  NO_INCREMENT_TAG,
  EMBED,
  MEDIA,
}

/** Тип тегированного параграфа */
export enum TAG_TYPE {
  BLOCKQOUTE,
  H1,
  H2,
  H3,
  H4,
  DIV,
  EXPERT_BLOCKQUOTE,
}

/** Тип параграфа c эмбедом */
export enum EMBED_TYPE {
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
  FACEBOOK,
  VK,
  VK_VIDEO,
  PLAYBUZZ,
  SKETCHFAB,
  EAGLEPLATFORM,
  GIPHY,
  QUIZ,
  GOOGLE_FORMS,
  TELEGRAM,
  PODCAST,
  SBER,
  VP_RAMBLER,
}

export enum MEDIA_TYPE {
  VIDEO,
  IMAGE,
}

export const BAN_EMBEDS = [
  EMBED_TYPE.FACEBOOK,
  EMBED_TYPE.INSTAGRAM,
  EMBED_TYPE.TWITTER,
];

export const VIDEO_EMBEDS = [
  EMBED_TYPE.YOUTUBE,
  EMBED_TYPE.VK_VIDEO,
  EMBED_TYPE.EAGLEPLATFORM,
  EMBED_TYPE.VP_RAMBLER,
];

// Урлы эмбедов
const URL_YOUTUBE = 'https://www.youtube.com/embed/';
const URL_VK = '//vk.com/js/api/openapi.js';
const URL_VK_VIDEO = '//vk.com/video_ext.php';
const URL_PLAYBUZZ = 'https://embed.playbuzz.com/sdk.js';
const URL_SKETCHFAB = 'https://sketchfab.com/models';
const URL_GIPHY = 'https://giphy.com/embed';
const URL_QUIZ = 'https://quiz.rambler.ru/widget/sdk.js';
const URL_GOOGLE_FORMS = 'https://docs.google.com/forms';
const URL_TELEGRAM = 'https://telegram.org/js/telegram-widget.js';
const URL_SBER = 'https://front.sber.link/playersberconfru.html';
const URL_TWITTER = 'https://platform.twitter.com/widgets.js';
const URL_INSTAGRAM = '//www.instagram.com/embed.js';
const URL_FACEBOOK = 'https://www.facebook.com/plugins/post.php';
const URL_VP_RAMBLER = 'https://vp.rambler.ru/player';
const URL_EAGLEPLATFORM = 'eagleplatform.com';

export const BLOCKQUOTE_OPEN_TAG_INDEX = 12;
export const BLOCKQUOTE_CLOSE_TAG_INDEX = -13;

// Другие уникальные опознаватели эмбедов
const ID_PODCAST = /id="buzzsprout-player-\d+"/gi;

/** Селекторы для поиска вставленного в тело фото/видео */
const IMAGE_SELECTOR = 'article__image-wrapper';
const VIDEO_SELECTOR = 'article__video-wrapper';

/** Селекторы для поиска цитаты эксперта */
const EXPERT_BLOCKQUOTE_SELECTOR = /className=('|")expert-embed('|")/gi;

const tagReplaceChars = (str: string) => str.slice(4, -5);

/** Мапка для работы с тегами */
export const TAGS_LIST: TagConfigType[] = [
  {
    flag: '<blockquote>',
    type: TAG_TYPE.BLOCKQOUTE,
    replace: (str: string) =>
      str
        .slice(BLOCKQUOTE_OPEN_TAG_INDEX, BLOCKQUOTE_CLOSE_TAG_INDEX)
        .trim()
        .replace(/\n/g, '<br />'),
  },
  {
    flag: EXPERT_BLOCKQUOTE_SELECTOR,
    type: TAG_TYPE.EXPERT_BLOCKQUOTE,
    replace: (str: string) => str,
  },
];

/** Мапка для работы с не инкрементируемыми тегами */
export const NO_INCREMENT_TAGS_LIST: TagConfigType[] = [
  {
    flag: '<h1>',
    type: TAG_TYPE.H1,
    replace: tagReplaceChars,
  },
  {
    flag: '<h2>',
    type: TAG_TYPE.H2,
    paragraphClass: 'h2',
    replace: tagReplaceChars,
  },
  {
    flag: '<h3>',
    type: TAG_TYPE.H3,
    replace: tagReplaceChars,
  },
  {
    flag: '<h4>',
    type: TAG_TYPE.H4,
    replace: tagReplaceChars,
  },
];

/** Мапка для работы с эмбедами */
export const EMBEDS_CONFIG: EmbedConfigType[] = [
  {
    type: EMBED_TYPE.TWITTER,
    flag: URL_TWITTER,
    link: URL_TWITTER,
    extraFlag: 'twitter-tweet',
    onReload: () => {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    },
    width: '550px',
    height: '195px',
  },
  {
    type: EMBED_TYPE.FACEBOOK,
    flag: URL_FACEBOOK,
    link: URL_FACEBOOK,
    extraFlag: 'www.facebook.com',
    onReload: null,
  },
  {
    type: EMBED_TYPE.INSTAGRAM,
    flag: URL_INSTAGRAM,
    link: URL_INSTAGRAM,
    extraFlag: 'instagram-media',
    onReload: () => {
      if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process();
      }
    },
    width: '540px',
    height: '900px',
  },
  {
    type: EMBED_TYPE.YOUTUBE,
    flag: URL_YOUTUBE,
    link: URL_YOUTUBE,
    onReload: null,
  },
  {
    type: EMBED_TYPE.VK,
    flag: URL_VK,
    link: URL_VK,
    onReload: null,
    width: 'auto',
  },
  {
    type: EMBED_TYPE.VK_VIDEO,
    flag: URL_VK_VIDEO,
    link: URL_VK_VIDEO,
    onReload: null,
  },
  {
    type: EMBED_TYPE.PLAYBUZZ,
    flag: URL_PLAYBUZZ,
    link: URL_PLAYBUZZ,
    onReload: null,
  },
  {
    type: EMBED_TYPE.SKETCHFAB,
    flag: URL_SKETCHFAB,
    link: URL_SKETCHFAB,
    onReload: null,
  },
  {
    type: EMBED_TYPE.GIPHY,
    flag: URL_GIPHY,
    link: URL_GIPHY,
    onReload: null,
  },
  {
    type: EMBED_TYPE.QUIZ,
    flag: URL_QUIZ,
    link: URL_QUIZ,
    onReload: null,
  },
  {
    type: EMBED_TYPE.GOOGLE_FORMS,
    flag: URL_GOOGLE_FORMS,
    link: URL_GOOGLE_FORMS,
    onReload: null,
  },
  {
    type: EMBED_TYPE.TELEGRAM,
    flag: URL_TELEGRAM,
    link: URL_TELEGRAM,
    onReload: null,
  },
  {
    type: EMBED_TYPE.SBER,
    flag: URL_SBER,
    link: URL_SBER,
    onReload: null,
  },
  {
    type: EMBED_TYPE.PODCAST,
    flag: ID_PODCAST,
    link: '',
    onReload: null,
  },
  {
    type: EMBED_TYPE.VP_RAMBLER,
    flag: URL_VP_RAMBLER,
    link: URL_VP_RAMBLER,
    onReload: null,
  },
  {
    type: EMBED_TYPE.EAGLEPLATFORM,
    flag: URL_EAGLEPLATFORM,
    link: URL_EAGLEPLATFORM,
    onReload: null,
  },
];

/** Мапка для работы с фото/видео в тексте */
export const MEDIA_LIST: MediaConfigType[] = [
  {
    type: MEDIA_TYPE.IMAGE,
    flag: IMAGE_SELECTOR,
  },
  {
    type: MEDIA_TYPE.VIDEO,
    flag: VIDEO_SELECTOR,
  },
];
