import React, { memo } from 'react';

import { EMBED_TYPE } from 'common/utils/clusterContent/config';

import { Telegram } from '../Telegram';
import { prepareDataForTelegram } from '../Telegram/utils';
import { Vk } from '../Vk';
import { prepareDataForVk } from '../Vk/utils';

type SPECIAL_EMBEDS_TYPE = EMBED_TYPE.VK | EMBED_TYPE.TELEGRAM;
export const SPECIAL_EMBEDS = [
  EMBED_TYPE.VK,
  EMBED_TYPE.TELEGRAM,
] as SPECIAL_EMBEDS_TYPE[];

type SpecialEmbedResolverPropsType = {
  text: string;
  embedType: SPECIAL_EMBEDS_TYPE;
};

/**
 * Разводчик для специальных эмбедов, которым недостаточно быть просто вставленными в код.
 * @param props.embedType - тип эмбеда. Если для него нет специального обработчика, то возвращается null;
 * @param props.text - содержимое параграфа, который был определен как эмбед.
 */
export const SpecialEmbedResolver = memo(
  ({ embedType, text }: SpecialEmbedResolverPropsType) => {
    if (embedType === EMBED_TYPE.TELEGRAM) {
      const data = prepareDataForTelegram(text);
      if (!data) return null;

      return <Telegram user={data.user} post={data.post} />;
    }

    if (embedType === EMBED_TYPE.VK) {
      const data = prepareDataForVk(text);
      if (!data) return null;

      return <Vk user={data.user} post={data.post} token={data.token} />;
    }

    return null;
  },
);
