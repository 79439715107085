import { NOT_ALLOWED_TAGS_INSIDE_PHRASING_CONTENT } from './constants';

/**
 * Важное замечание:
 *   В функциях ниже регулярка не используется в целях повышения производительности.
 *   Проверка на наличие '<' и '<{название запрещенного тега}' является более легкой, а
 *    шанс появления граничного результата, из-за которого будет выбрана неправильная
 *    обертка, довольно низок и не несет за собой тяжелых последствий. Однако эти
 *    функции не рекомендуется использовать вне компонента TextParagraph ввиду их
 *    специфичности.
 */

/**
 * Проверка параграфа на наличие тэгов
 * @param text - текст параграфа
 */
export const hasTags = (text: string) => text.includes('<');

/**
 * Проверка параграфа на наличие тэгов, которым запрещено распологаться внутри <p>
 * @param text - текст параграфа
 */
export const hasNotPhrasingContent = (text: string) =>
  NOT_ALLOWED_TAGS_INSIDE_PHRASING_CONTENT.some((tag) =>
    text.includes(`<${tag}`),
  );
