import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectRuntime } from 'common/redux/runtime/selectors';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

export type AnalyticsConfigType = {
  counters: COUNTER_ID[];
  showTarget?: string;
  clickTarget?: string;
};

/**
 * Хук отправляет события show
 * и возвращает обработчик для события click
 * для одного таргета
 * @param configs - параметры для событий
 * @param configs.counters - массив alias'ов счетчиков
 * @param configs.showTarget - идентификатор цели для события show
 * @param configs.clickTarget - идентификатор цели для события click
 */

export const useAnalytics = (
  { counters, showTarget, clickTarget }: AnalyticsConfigType,
  isTargetShowed: boolean = true,
) => {
  const runtime = useSelector(selectRuntime, shallowEqual);

  useEffect(() => {
    if (isTargetShowed) {
      counters.forEach(
        (counterType) =>
          showTarget && yaReachGoal(runtime, counterType, showTarget),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reachClick = useCallback(
    () => {
      counters.forEach(
        (counterType) =>
          clickTarget && yaReachGoal(runtime, counterType, clickTarget),
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    reachClick,
  };
};
