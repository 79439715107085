import cn from 'classnames';
import React, { memo, useEffect, useRef } from 'react';

type VideoInlinePropsType = {
  clusterIndex: number;
  styles: { [className: string]: string };
  embedCode: string | undefined;
  clusterUrl: string;
};

/**
 * Компонент для вставки скрипта видео из embedCode
 * @param clusterIndex - индекс кластера в бесконечном скролле
 * @param styles - стили
 * @param embedCode - код для вставки
 * @param clusterUrl - URL кластера, в который встраивается плеер
 */
export const VideoInline = memo(
  ({
    clusterIndex = 0,
    styles,
    embedCode = '',
    clusterUrl,
  }: VideoInlinePropsType) => {
    const playerRef = useRef<HTMLDivElement>(null);

    const playerClasses = cn(styles.player, {
      [styles['player--loading']]: !embedCode,
    });

    const isFirstCluster = clusterIndex === 0;

    useEffect(() => {
      // добавление скриптов (embedCode) с админки для динамически
      // подгружаемых кластеров, чтобы не ломался скрипт
      if (!isFirstCluster && playerRef?.current) {
        playerRef.current.innerHTML = embedCode;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!embedCode) return null;

    return isFirstCluster ? (
      <div
        data-referrer={clusterUrl}
        className={playerClasses}
        dangerouslySetInnerHTML={{ __html: embedCode }}
        suppressHydrationWarning
      />
    ) : (
      <div
        data-referrer={clusterUrl}
        ref={playerRef}
        className={playerClasses}
      />
    );
  },
);
