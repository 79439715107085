import React, { memo } from 'react';
import Helmet from 'react-helmet';

import { MAX_VIDEO_PREVIEW } from 'config/constants/maxVideoPreview';

type MetaMaxVideoPreviewPropTypes = {
  maxVideoPreview?: number;
};

/**
 * Компонент мета-информации для роботов по максимальной длине предпоказа видео
 * @param maxVideoPreview - длина предпоказа видео
 */
export const MetaMaxVideoPreview = memo(
  ({ maxVideoPreview = MAX_VIDEO_PREVIEW }: MetaMaxVideoPreviewPropTypes) => (
    <Helmet>
      <meta name="robots" content={`max-video-preview:${maxVideoPreview}`} />
    </Helmet>
  ),
);
