import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BreadcrumbList, NewsArticle } from 'schema-dts';

import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { JsonLd } from 'utils/jsond';

import { useItemForBreadcrumbList, useItemForNewsArticle } from './hooks';

type NewsArticleSchemaPropsType = {
  cluster: ClusterData | undefined;
};

/**
 * Разметка schemaOrg для кластера.
 * @param cluster - объект кластера.
 */
export const NewsArticleSchema = memo(
  ({ cluster }: NewsArticleSchemaPropsType) => {
    const topic = useSelector(
      selectTopicById(cluster?.mainTopicId),
      shallowEqual,
    );

    const itemForNewsArticle = useItemForNewsArticle(cluster);
    const itemForBreadcrumbList = useItemForBreadcrumbList(
      cluster?.mainTopicId,
    );

    return (
      <>
        <JsonLd<NewsArticle> item={itemForNewsArticle} />
        {!!topic && <JsonLd<BreadcrumbList> item={itemForBreadcrumbList} />}
      </>
    );
  },
);
