import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { VideoObjectSchema } from 'common/components/schemaOrg/VideoObject';
import { VideoInline } from 'common/components/VideoInline';
import { VideoPlayer, VIDEO_DATA_TYPE } from 'common/components/VideoPlayer';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { checkIsNewPlayer } from 'common/utils/checkIsVideoPlatform';
import { PuidsType } from 'config/constants/common';
import { generateClusterUrl } from 'utils/urlGenerator';

import { generatePlayerId } from '../VideoPlayer/utils';

import s from './styles.module.css';

type VideoPropsType = {
  cluster: ClusterData;
  topic?: StateTopicType;
  clusterIndex?: number;
  stopMinipleerRef?: React.MutableRefObject<HTMLElement>;
  styles?: StylesType;
  type?: 'default' | 'multi';
  puids: PuidsType | undefined;
  height?: number;
};

/**
 * Видео кластера
 * @param cluster - кластер
 * @param topic - топик кластера
 * @param clusterIndex - индекс кластера в бесконечном скролле
 * @param styles - стили
 * @param puids - рекламные пуиды
 * @param stopMinipleerRef - блок-стоппер для миниплеера
 * @param height - фиксированная высота видео
 */
export const Video = memo(
  ({
    cluster,
    topic,
    clusterIndex = 0,
    stopMinipleerRef,
    styles = s,
    puids,
    height,
  }: VideoPropsType) => {
    const domainConfig = useSelector(selectDomainConfig, shallowEqual);

    const {
      id: clusterId,
      normalizedTitle,
      video: { embedCode, videoData },
    } = cluster;

    const clusterUrl = useMemo(
      () =>
        generateClusterUrl({
          clusterId,
          normalizedTitle,
          domainConfig,
          topic,
        }),
      [clusterId, normalizedTitle, domainConfig, topic],
    );

    const isEagleplatformVideo = checkIsNewPlayer(embedCode);

    const isBroadcast = videoData?.type === VIDEO_DATA_TYPE.broadcast;

    return (
      <div className={s.container}>
        <ClusterImageObject cluster={cluster} />
        <VideoObjectSchema cluster={cluster} />
        {isEagleplatformVideo ? (
          <VideoPlayer
            clusterUrl={clusterUrl}
            embedCode={embedCode}
            puids={puids}
            styles={styles}
            playerId={generatePlayerId(clusterId, clusterIndex)}
            miniPlayerStopRef={stopMinipleerRef}
            docking={undefined}
            dockingDirection={undefined}
            needMiniPlayer={false}
            isBroadcast={isBroadcast}
            height={height}
            clusterId={clusterId}
          />
        ) : (
          <VideoInline
            clusterIndex={clusterIndex}
            styles={styles}
            embedCode={embedCode}
            clusterUrl={clusterUrl}
          />
        )}
      </div>
    );
  },
);
