import React, { useEffect, useRef, useState } from 'react';

import { EMBED_TYPE } from 'common/utils/clusterContent/config';

import s from './styles.module.css';

const DEFAULT_WIDTH = '100%';
const DEFAULT_HEIGHT = '315px';
const HOW_CHECK_COUNT_MAX = 10;
const DOM_SELECTORS = {
  iframe: 'iframe',
  script: 'script[src]',
  blockquote: 'blockquote',
  quizEmbed: 'div[data-quiz-id]',
  vkEmbed: '[id^=vk_post]',
  playbuzzEmbed: '[class^=playbuzz]',
};

type UseLoadedPlaceholderArgumenType = {
  width: EmbedConfigType['width'];
  height: EmbedConfigType['height'];
  type: EMBED_TYPE;
};

/**
 * Хук для получения плейсхолдера для эмбеда и отслеживания загрузки эмбеда
 * @param width - ширина эмбеда из конфига
 * @param height - высота эмбеда из конфига
 * @param type - тип эмбеда
 */
export const useLoadedPlaceholder = <T extends HTMLElement>({
  width,
  height,
  type,
}: UseLoadedPlaceholderArgumenType) => {
  const ref = useRef<T>(null);
  const checkTimerId = useRef<NodeJS.Timeout | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [size, setSize] = useState({
    placeholderWidth: DEFAULT_WIDTH,
    placeholderHeight: DEFAULT_HEIGHT,
  });

  const resourceLoadHandle = () => setIsLoaded(true);

  const resourceLoadAddListener = (loadNode: HTMLElement | null): void => {
    if (loadNode) {
      loadNode.onload = () => {
        resourceLoadHandle();
      };

      loadNode.onerror = () => {
        console.error('Ошибка загрузки скрипта эмбеда');
      };
      /* eslint-enable no-param-reassign */
    }
  };

  // проверка отработки скрипта вставки эмбеда
  // если что-то со скриптом не так и iframe не вставлен -
  // скрываем плэйсхолдер
  const checkInnerIframe = () => {
    const delay = 200;
    let checkCount = 0;

    checkTimerId.current = setInterval(() => {
      const embedIframe = ref.current?.querySelector(DOM_SELECTORS.iframe);

      if (embedIframe || checkCount >= HOW_CHECK_COUNT_MAX) {
        setIsLoaded(true);
        clearInterval(checkTimerId.current as NodeJS.Timeout);
      }

      checkCount += 1;
    }, delay);
  };

  const iframeLoader = () => {
    // скрипт, который грузит iframe эмбеда
    const scriptNode = ref.current?.querySelector(DOM_SELECTORS.script);

    if (!scriptNode) return;

    // Скрипт эмбеда загружается, в эмбеде появляется iframe
    const nodeChangeHandle = () => {
      const iframeNode = ref.current?.querySelector<HTMLIFrameElement>(
        DOM_SELECTORS.iframe,
      );

      clearInterval(checkTimerId.current as NodeJS.Timeout);

      if (iframeNode) {
        resourceLoadAddListener(iframeNode);
      }
    };

    if (ref.current) {
      // следим за тем, когда в контейнер эмбеда что то прилетит ( например iframe :) )
      const observer = new MutationObserver(nodeChangeHandle);

      observer.observe(ref.current, {
        childList: true,
        subtree: true,
        characterDataOldValue: false,
      });
    }

    checkInnerIframe();
  };

  // Определение размеров плейсхолдера. Навешивание слушателя при эмбеде-iframe
  useEffect(() => {
    const embedContainer = ref.current;

    if (!embedContainer) return;

    // Facebook, Vk.video, Sketchfab, Youtube, Giphy, Google-формы
    const embedIframeNode = embedContainer.querySelector<HTMLIFrameElement>(
      DOM_SELECTORS.iframe,
    );
    const embedScriptNode = embedContainer.querySelector<HTMLScriptElement>(
      DOM_SELECTORS.script,
    );

    let embedNode = embedIframeNode;

    // грузится либо сразу iframe, либо скрипт, который загрузит iframe
    resourceLoadAddListener(embedIframeNode || embedScriptNode);

    // Instagram, Twitter
    if (type === EMBED_TYPE.INSTAGRAM || type === EMBED_TYPE.TWITTER) {
      embedNode = embedContainer.querySelector(DOM_SELECTORS.blockquote);
    }

    // Рамблер/петиции, Рамблер/опросы
    if (type === EMBED_TYPE.QUIZ) {
      embedNode = embedContainer.querySelector(DOM_SELECTORS.quizEmbed);
    }

    // VK
    if (type === EMBED_TYPE.VK) {
      embedNode = embedContainer.querySelector(DOM_SELECTORS.vkEmbed);
    }

    // PLAYBUZZ
    if (type === EMBED_TYPE.PLAYBUZZ) {
      embedNode = embedContainer.querySelector(DOM_SELECTORS.playbuzzEmbed);
    }

    if (embedNode) {
      const embedNodeWidth = !embedNode.width?.includes('px')
        ? `${embedNode.width}px`
        : null;
      const embedNodeHeight = !embedNode.height?.includes('px')
        ? `${embedNode.height}px`
        : null;

      setSize({
        placeholderWidth: embedNodeWidth || width || DEFAULT_WIDTH,
        placeholderHeight: embedNodeHeight || height || DEFAULT_HEIGHT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current) iframeLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const placeholder = !isLoaded ? (
    <div
      style={{
        width: size.placeholderWidth,
        height: size.placeholderHeight,
      }}
      className={s.placeholder}
    />
  ) : null;

  return { ref, isLoaded, placeholder };
};
