import React, { memo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useObserver } from 'common/hooks/useObserver';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { checkBotsDecorator } from 'utils/checkBotsDecorator';
import { incrementCounters } from 'utils/counters';
import { glick } from 'utils/counters/glick';

type IncrementCountersTriggerPropsType = {
  firstCluster: boolean;
  clusterID: CardData['id'];
  projectID: number | undefined;
};

const INCREMENT_METRICKS_TIMEOUT = 400;

/**
 * Компонент триггера метрик для кластера
 * @param firstCluster - флаг что кластер первый в бесконечном скролле
 * @param clusterID - id кластера
 * @param projectID - id проекта, к которому относится кластер
 */
export const IncrementCountersTrigger = memo(
  ({
    firstCluster,
    clusterID,
    projectID,
  }: IncrementCountersTriggerPropsType) => {
    const pageName = useSelector(selectPageName);
    const runtime = useSelector(selectRuntime, shallowEqual);
    // на первом кластере все метрики, кроме glick уже отправлены
    // поэтому наблюдатель переводим в true
    const countersIncrementRef = useRef(firstCluster);
    // метрика в glick на первом кластере еще не отправлена,
    // поэтому для него отдельный ref-наблюдатель
    const incrementClicks = useRef(false);

    const countersCallback = () => {
      if (!incrementClicks.current && typeof projectID === 'number') {
        incrementClicks.current = true;
        checkBotsDecorator(() => glick(clusterID, projectID));
      }

      if (!countersIncrementRef.current) {
        countersIncrementRef.current = true;
        setTimeout(() => {
          incrementCounters({ runtime, pageName });
        }, INCREMENT_METRICKS_TIMEOUT);
      }
    };

    const triggerRef = useObserver<HTMLDivElement>({
      callback: countersCallback,
    });

    return <div ref={triggerRef} />;
  },
);
