import React, { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAnalytics } from 'common/hooks/useAnalytics';
import { topicButtonAnalyticsConfig } from 'common/hooks/useAnalytics/configs';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { TOPIC_BUTTON_CLASS_NAME } from './constants';
import { hasNotPhrasingContent, hasTags } from './utils';

type TextParagraphPropsType = {
  text: string;
  paragraphIndex?: number;
  isMulticluster?: boolean;
};

/**
 * Параграф с текстом во фразовом контексте
 * @param text - текст параграфа
 */
export const PhrasingParagraph = forwardRef<
  HTMLParagraphElement,
  TextParagraphPropsType
>(({ text }, ref) => (
  <p
    ref={ref}
    suppressHydrationWarning
    dangerouslySetInnerHTML={{ __html: text }}
  />
));

/**
 * Параграф с блочным элементом
 * @param text - текст параграфа
 */
export const BlockParagraph = forwardRef<
  HTMLDivElement,
  TextParagraphPropsType
>(({ text }, ref) => (
  <div
    ref={ref}
    suppressHydrationWarning
    dangerouslySetInnerHTML={{ __html: text }}
  />
));

/**
 * Параграф для мультикластера
 * @param text - текст параграфа;
 * @param paragraphIndex - индекс параграфа для топ100.
 */
export const MulticlusterParagraph = forwardRef<
  HTMLParagraphElement,
  TextParagraphPropsType
>(({ text, paragraphIndex }, ref) => {
  const top100AttributeParagraph = useTop100AttributeWithValue(
    paragraphIndex ? `item_${paragraphIndex + 1}` : 'item',
  );
  const dataBlock = Object.keys(top100AttributeParagraph)[0];
  const regExp = useMemo(
    () => new RegExp(`<a ${dataBlock}="click_link_contentpage"`, 'g'),
    [dataBlock],
  );

  const textWithNewTop100AttributeLink = useMemo(() => {
    const source = text.match(/\/\/(.)+?[/"]/gi)?.[0]?.slice(2, -1);

    if (!source) return text;

    /**
     * Ниже используется нотация для top100 (они же dataBlocks) и она не корректна.
     * В нашем случае нельзя использовать обращение через двойное двоеточие, так что
     *  хорошо было бы переписать это дело в формат типа "source_link_${source}", но Продакт решил
     *  что нафиг это трогать. Так что не трогаем.
     * Но из-за такого подхода может портиться статистика.
     */
    return text.replace(
      regExp,
      `<a aria-label="Перейти на страницу источника новости" ${dataBlock}=source_link::${source}`,
    );
  }, [dataBlock, regExp, text]);

  return (
    <p
      ref={ref}
      suppressHydrationWarning
      dangerouslySetInnerHTML={{ __html: textWithNewTop100AttributeLink }}
      {...top100AttributeParagraph}
    />
  );
});

/**
 * Компонент-фасад отображения корректного html в зависимости от того,
 * содержит ли html фразовый контекст.
 * https://html.spec.whatwg.org/#phrasing-content
 * @param text - текст параграфа;
 * @param paragraphIndex - индекс параграфа для топ100;
 * @param isMulticluster - флаг, что это мультикластер.
 */
export const TextParagraph = memo(
  ({
    text,
    isMulticluster = false,
    paragraphIndex,
  }: TextParagraphPropsType) => {
    const paragraphRef = useRef<HTMLParagraphElement>(null);
    const isMobile = useSelector(selectIsMobile);
    const isTopicButton = text.includes(TOPIC_BUTTON_CLASS_NAME);

    // аналитика для блока "Читайте по теме"
    const { reachClick: reachTopicButtonClick } = useAnalytics(
      topicButtonAnalyticsConfig,
      isTopicButton && isMobile,
    );

    useEffect(() => {
      if (isTopicButton && isMobile && paragraphRef.current) {
        const topicButtonContainer = paragraphRef.current;
        const topicButtonElement =
          topicButtonContainer.querySelector<HTMLElement>(
            `.${TOPIC_BUTTON_CLASS_NAME}`,
          );

        topicButtonElement?.addEventListener('click', reachTopicButtonClick);

        return () =>
          topicButtonContainer.removeEventListener(
            'click',
            reachTopicButtonClick,
          );
      }

      return undefined;
    }, [isMobile, isTopicButton, reachTopicButtonClick]);

    if (isMulticluster) {
      return (
        <MulticlusterParagraph
          ref={paragraphRef}
          text={text}
          paragraphIndex={paragraphIndex}
        />
      );
    }

    // Порядок вызова функций обязателен. Иначе будет нанесен лишний урон производительности.
    if (hasTags(text) && hasNotPhrasingContent(text)) {
      return <BlockParagraph ref={paragraphRef} text={text} />;
    }

    return <PhrasingParagraph ref={paragraphRef} text={text} />;
  },
  () => true,
);
