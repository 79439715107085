import { getParagraphType } from 'common/utils/clusterContent/getParagraphType';
import { AUTOTAG_TYPE, FILTER_AUTOTAG_TYPE } from 'config/constants/cluster';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { replacingStr } from 'utils/replaceLinks';

import { PARAGRAPH_TYPE } from '../config';
import { GetArticleOptionsType } from '../typings';

import { generateLink } from './generateLink';
import {
  getCorrectMentions,
  ExtendedMention,
  LinkMention,
} from './getCorrectMentions';
import { getLinksMentions } from './getLinksMentions';
import {
  sortByOffset,
  CAPTION_REGEXP,
  removeMarkupFromClusterBody,
} from './helpers';
import { Range, ClusterBodyElemType } from './range';

const HUMAN_READABLE_TAG_TYPE: Record<string, string> = {
  [AUTOTAG_TYPE.organization]: 'organization',
  [AUTOTAG_TYPE.media]: 'media',
  [AUTOTAG_TYPE.person]: 'person',
  [AUTOTAG_TYPE.country]: 'region',
  [AUTOTAG_TYPE.region]: 'region',
  [AUTOTAG_TYPE.city]: 'region',
  [AUTOTAG_TYPE.auto]: 'auto',
  [AUTOTAG_TYPE.movie]: 'movie',
};

type GetLinkHTMLType = {
  tagAlias: string;
  tagType: string;
  linkText: string;
};

/**
 * Создает ссылку для текущей версии дизайна.
 */
const getCommonLinkHTML = ({
  tagAlias,
  tagType,
  linkText,
}: GetLinkHTMLType): string => {
  const readableTagType: string = HUMAN_READABLE_TAG_TYPE[tagType];
  const href: string =
    readableTagType === AUTOTAG_TYPE.region
      ? `/${tagAlias}/`
      : `/${readableTagType}/${tagAlias}/`;
  const link: string = generateLink({ href, readableTagType, text: linkText });

  return link;
};

/**
 * Обощенный генератор варианта ссылки в зависимости от того, что пришло снаружи - тег или ссылка.
 * Кнопку персон убираем совсем в https://jira.rambler-co.ru/browse/NEWS-11179
 * Кнопку персон вернули для nativeApp в https://jira.rambler-co.ru/browse/NEWS-11163
 * @param props.tag - опциональный объект автотега, если такой есть;
 * @param props.href - опциональный урл для ссылки, если такой есть;
 * @param props.linkText - содержимое ссылки;
 * @param props.isAppDesign - флаг, что это дизайн приложения.
 */
const getLinkHTML = ({
  tag,
  href,
  linkText,
}: {
  tag: ATAutotagInfo | null;
  href: string | null;
  linkText: string;
}) => {
  if (tag) {
    return getCommonLinkHTML({
      tagAlias: tag.alias,
      tagType: tag.autotagType,
      linkText,
    });
  }

  return getBlockquoteLink(href || '', linkText);
};

/**
 * Создает ссылку для цитаты
 */
const getBlockquoteLink = (href: string, linkText: string) =>
  `<a href="${href}" rel="noopener noreferrer" target="_blank">${linkText}</a>`;

/**
 * Принимает текст и упоминания, возвращает протегированный текст
 */
const replaceMentions = (
  text: string,
  mentions: (ExtendedMention | LinkMention)[],
  hasUnwantedMarkup: boolean,
) => {
  let textWithTags = hasUnwantedMarkup
    ? removeMarkupFromClusterBody(text)
    : text;
  let offsetChange = 0;
  let captionOffset = 0;

  mentions.forEach((mention) => {
    if (hasUnwantedMarkup) {
      captionOffset =
        mention.offset -
        removeMarkupFromClusterBody(text.slice(0, mention.offset)).length;
    }

    const linkText = text.slice(
      mention.offset,
      mention.offset + mention.length,
    );
    const tag = 'tag' in mention ? mention.tag : null;
    const href = 'href' in mention ? mention.href : null;
    const linkHTML = getLinkHTML({ tag, href, linkText });

    // Replace
    const correctedOffset = mention.offset - captionOffset + offsetChange;
    const articleBeforeLink = textWithTags.slice(0, correctedOffset);
    const articleAfterLink = textWithTags.slice(
      correctedOffset + linkText.length,
    );

    textWithTags = articleBeforeLink + linkHTML + articleAfterLink;

    // Modify offset to match new article length
    offsetChange += linkHTML.length - mention.length;
  });

  return textWithTags;
};

/**
 * Элементы тела кластера внутрь которых нельзя вставлять упоминания
 */
const DONT_INSERT_INSIDE_ELEMS: ClusterBodyElemType[] = [
  'a',
  'h1',
  'h2',
  'h3',
  'h4',
];

/*
 * Из-за наличия в тексте emoji автотеги приходят немного смещенными.
 * Методом проб было выявлено, что, вроде как, теггер считает эмодзи за символ длинной 1
 * Хотя на самом деле emoji могут быть разной длинны.
 * Была взята регулярка https://www.regextester.com/106421 и доработана. С ее помощью заменяем все emoji на символ пробела
 * Вернулись спецсимволы валют в задаче https://jira.rambler-co.ru/browse/NEWS-9486
 */
const EMOJI_REGEXP =
  /(\u00a9|\u00ae|[\u2000-\u2012]|[\u2123-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
const EMOJI_REPLACE_SYMBOL = ' ';
const replaceEmoji = (text: string) =>
  text.replace(EMOJI_REGEXP, EMOJI_REPLACE_SYMBOL);

const SCRAPPER_LINK_REGEXP = /(<a.*?scr-link.*?>)(.*?)(<\/a>)/g;
const SCRAPPER_LINK_TEXT_MARK = '`';

type ScrapperLinkType = {
  link: string;
  linkText: string;
  markedLinkText: string;
};

/**
 * Удаляем все ссылки, которые агрегировал скраппер с других ресурсов
 * @param text - текст без автотегов, но с ссылками от скраппера
 */
const removeScrapperLinks = (text: string): [string, ScrapperLinkType[]] => {
  const scrapperLinks: ScrapperLinkType[] = Array.from(
    text.matchAll(SCRAPPER_LINK_REGEXP),

    ([link, _openTag, linkText]) => ({
      link,
      linkText,
      markedLinkText: linkText.replace(
        linkText[0],
        `${SCRAPPER_LINK_TEXT_MARK}`,
      ),
    }),
  );

  const textWithoutLinks = scrapperLinks.reduce(
    (workingText, { link, markedLinkText }) =>
      workingText.replace(link, markedLinkText),
    text,
  );

  return [textWithoutLinks, scrapperLinks];
};

/**
 * Возвращаем обратно ссылки от скраппера, которые вырезали ранее
 * @param text - текст кластера с вставленными автотегами
 * @param scrapperLinks - массив сматчинных регуляркой ссылок от скраппера
 */
const addScrapperLinks = (
  text: string,
  scrapperLinks: ScrapperLinkType[],
): string =>
  scrapperLinks.reduce((workingText, { link, linkText, markedLinkText }) => {
    // Экранируем спец.символы регулярных выражений
    const escapedMarkedLinkText = markedLinkText.replace(
      /([()[{*+.$^\\|?])/g,
      '\\$1',
    );
    const textInLinkRegExp = new RegExp(
      `<a.*>.*${escapedMarkedLinkText}.*<\\/a>`,
    );

    // Проверка, не обернут ли этот текст уже в какую-либо ссылку
    if (!textInLinkRegExp.test(text)) {
      return workingText.replace(markedLinkText, link);
    }

    // Меняем перебитый автотегами помеченный текст в линках скрапера на текст без пометки
    return workingText.replace(markedLinkText, linkText);
  }, text);

const defaultOptions = {
  isLongread: false,
  withFilterTags: false,
  onlyFirst: true,
};

const EMPTY_BLOCKQUOTE = '<blockquote></blockquote>';
const SPLIT_SYMBOL = '\n\n';
const EMPTY_STRING = ' '.repeat(EMPTY_BLOCKQUOTE.length - SPLIT_SYMBOL.length);
const EMPTY_BLOCKQUOTE_REGEXP = new RegExp(EMPTY_BLOCKQUOTE, 'g');

/**
 * Принимает тело кластера, автотэги и данные драфта с офсетами для ссылок в цитатах, возвращает протегированный текст
 * @param article тело кластера
 * @param tags автотеги
 * @param draft данные для разных блоков кластера
 * @param onlyFirst Опц. если true упоминать один тэг только один раз, если false все разы
 * @param isLongread опция что кластер longread
 * @param withFilterTags опция что теги нужно отфильтровать
 */
export const getArticleWithTags = (
  article: ClusterData['body'],
  tags: ATAutotag[] | undefined,
  draft: RawDraftContentState,
  projectAlias: NEWS_PROJECT_ALIASES,
  {
    isLongread = false,
    withFilterTags = false,
    onlyFirst = true,
  }: GetArticleOptionsType = defaultOptions,
) => {
  /**
   * в https://jira.rambler-co.ru/browse/NEWS-8221 отлючили автотеги для лонгридов =>
   * в https://jira.rambler-co.ru/browse/NEWS-8230 не заменяем эмоджи которые могли мешать автотегам для лонгридов
   *
   * вернуть по необходимости
   */

  // костыль костыльский на случай если в кластере идут 2 цитаты подряд, тк в админке перенос между ними может быть оформлен цитатой вместо \n\n
  // в итоге может появиться цитата в цитате как в https://sport.eve.rambler.ru:3000/winter/38971421-mne-dazhe-zhalko-russkih-na-zapade-perestayut-ponimat-chto-delaet-mok/
  // EMPTY_STRING нужна для сохранения длины параграфа иначе автотеги неправильно считаются и смещаются
  const articleWithoutEmptyBlockQuotes = article.replace(
    EMPTY_BLOCKQUOTE_REGEXP,
    `${EMPTY_STRING}${SPLIT_SYMBOL}`,
  );
  const transformedArticle = isLongread
    ? articleWithoutEmptyBlockQuotes
    : replaceEmoji(articleWithoutEmptyBlockQuotes);
  // Получение чистого текста и агрегированных ссылок скрапера
  const [filteredArticle, scrapperLinks] =
    removeScrapperLinks(transformedArticle);

  // Проверка, куда теги вставлять нельзя
  const clusterElemsRanges = Range.getElemsRanges(
    DONT_INSERT_INSIDE_ELEMS,
    filteredArticle,
  );

  // https://jira.rambler-co.ru/browse/NEWS-8276
  // TODO(NEWS-0000): удалить фильтрацию когда мы полностью перенесем все страницы (или по необходимости)
  const insertTags: ATAutotag[] | undefined = withFilterTags
    ? tags?.filter((tag) => !FILTER_AUTOTAG_TYPE[tag.autotagType])
    : tags;

  // Получение списка ссылок с офсетами для вставки в цитаты
  const blockqouteLinks = getLinksMentions(
    articleWithoutEmptyBlockQuotes,
    draft,
  );

  // Получаем массив строк, которые надо исключить
  const deletedMarkupStrings = filteredArticle.match(CAPTION_REGEXP);

  // Получение списка автотегов для вставки
  const autotagsMentions = getCorrectMentions({
    tags: insertTags || [],
    onlyFirst,
    rangesToExclude: clusterElemsRanges,
    customFilter: (mention) => {
      // Исключаем тег, который вставляется в удаленную разметку.
      let excludeMention = true;

      if (deletedMarkupStrings) {
        const mentionTagAlias = mention.tag.alias.split('-')[0];

        excludeMention = !deletedMarkupStrings.some((item) =>
          item.toLocaleLowerCase().includes(mentionTagAlias),
        );
      }

      const inBody = mention.where === 'body';
      const notMedia = mention.tag.autotagType !== AUTOTAG_TYPE.media;
      const notMovie = mention.tag.autotagType !== AUTOTAG_TYPE.movie;

      return inBody && (notMedia || notMovie) && excludeMention;
    },
  });

  const hasPersonMentions = autotagsMentions.some(
    (mention) => mention.tag.autotagType === AUTOTAG_TYPE.person,
  );

  const mentions = sortByOffset([...autotagsMentions, ...blockqouteLinks]);

  // Текст с автотегами
  const articleWithMentions = replaceMentions(
    filteredArticle,
    mentions,
    !!deletedMarkupStrings,
  );

  // Текст с автотегами и агрегированными скрапером ссылами
  const articleWithMentionsAndScrapperLinks = addScrapperLinks(
    articleWithMentions,
    scrapperLinks,
  );

  const articleReplacedUrl = articleWithMentionsAndScrapperLinks
    .split(SPLIT_SYMBOL)
    .map((paragraph) => {
      const { type } = getParagraphType(paragraph);

      if (
        (type === PARAGRAPH_TYPE.TEXT ||
          type === PARAGRAPH_TYPE.INCREMENT_TAG) &&
        paragraph.includes('redirect_url=')
      ) {
        return replacingStr(paragraph, projectAlias);
      }

      return paragraph;
    })
    .join(SPLIT_SYMBOL);

  return {
    articleWithTags: articleReplacedUrl,
    meta: {
      hasPersonMentions,
    },
  };
};
