import React from 'react';
import { createUseStyles } from 'react-jss';

import { style } from './style';

const AMP_PAGE_SOURCE_REG_EXP = /<!-- ampPageSrc: ((.)*) -->/i;

type PropsType = { text: string; canonicalUrl: string };

const useStyles = createUseStyles(style);

/**
 * Получение разметки для эмбеда amp квизов
 * @param text - разметка эмбеда
 * @param canonicalUrl - адрес канонической страницы
 */
export const QuizEmbed = ({ text, canonicalUrl = '' }: PropsType) => {
  const s = useStyles();

  const ampPageSrcBase = text.match(AMP_PAGE_SOURCE_REG_EXP)?.[1];

  if (!ampPageSrcBase) {
    return null;
  }

  const ampPageSrcUrl = new URL(ampPageSrcBase);

  ampPageSrcUrl.searchParams.append('pageUrl', canonicalUrl);

  const ampPageSrc = ampPageSrcUrl.toString();

  /* элемент внутри amp-iframe нужен для работы параметра resizable
   * https://amp.dev/documentation/components/amp-iframe/#iframe-resizing
   */
  return (
    <amp-iframe
      class={s.container}
      width="400"
      height="400"
      sandbox="allow-scripts allow-same-origin allow-popups"
      layout="responsive"
      frameborder="0"
      resizable=""
      src={ampPageSrc}
    >
      {/* @ts-expect-error: можно не обращать внимание */}
      <span overflow="" tabIndex={0} role="button" aria-label="Показать опрос">
        Показать опрос
      </span>
    </amp-iframe>
  );
};
