import React, { memo } from 'react';

import { checkIsEagleplatform } from 'common/utils/checkIsVideoPlatform';
import {
  checkIframeInText,
  changeVideoIframe,
} from 'common/utils/searchIframe';
import { PuidsType } from 'config/constants/common';
import { addInstBanCommentInHtmlString } from 'utils/socialsBan/addBanComment';

import { useLoadedPlaceholder } from './useLoadedPlaceholder';

type MediaParagraphPropsType = {
  topic?: TopicType;
  text: string;
  puids?: PuidsType;
};

/** https://jira.rambler-co.ru/browse/NEWS-7927 - основная задача */
/** https://jira.rambler-co.ru/browse/NEWS-8446 - задача из-за которой закомментирован код */
// const DO_DIR1_TOPIC_ID = [465, 151, 153, 145, 144, 150, 154, 143, 147, 148, 157, 160, 158, 146, 159, 152, 156, 226, 219, 240, 504];

/**
 * Параграф с фото или видео в тексте
 * @param text - текст параграфа
 * @param puids - рекламные пуиды
 * @param topic - данные о рубрике
 */
export const MediaParagraph = memo(
  ({
    text,
    puids,
  }: // topic, https://jira.rambler-co.ru/browse/NEWS-8446
  MediaParagraphPropsType) => {
    // Не проставляем ad_template_id для данных рубрик
    // https://jira.rambler-co.ru/browse/NEWS-8446

    let html: string = text;

    if (checkIframeInText(text) && checkIsEagleplatform(text)) {
      html = changeVideoIframe(text, puids);
    }

    const ref = useLoadedPlaceholder<HTMLDivElement>(html);

    return (
      <div
        ref={ref}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: addInstBanCommentInHtmlString(html),
        }}
      />
    );
  },
);
